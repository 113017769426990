import React from "react";
import {
  Font,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../assets/logo.png";
// import Poppins from "../../assets/fonts/Poppins-Regular.ttf";
// import PoppinsSemiBold from "../../assets/fonts/Poppins-SemiBold.ttf";
// import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import Halvetica from "../../assets/fonts/helvetica-light-587ebe5a59211.ttf";
import HalveticaBold from "../../assets/fonts/Helvetica-Bold.ttf";

// const dateTimeApiformat = (dateStr) => {
//   let dateArr = dateStr.split(/[/:\-T]/);
//   dateArr.shift();
//   dateArr.shift();
//   dateArr.shift();
//   const [hh, mins, ss] = dateArr;
//   const hourlyTime = `${hh}:${mins}:${ss.split(".")[0]}`;
//   return hourlyTime;
// };

const getRowColor = (severity) => {
  let color =
    severity == 3
      ? "#F9DEB9"
      : severity == 2
      ? "#FDC188"
      : severity == 1
      ? "#FC9A91"
      : "#D3E5F9";
  return color;
};

const getSeverity = (severity) => {
  let temp =
    severity == 3
      ? "Low"
      : severity == 2
      ? "Medium"
      : severity == 1
      ? "High"
      : severity == 4
      ? "None"
      : "Unknown";
  return temp;
};

// const getCurrentDateForHeader = (str) => {
//   let [yyyy, mm, dd, hh, mi] = str.split(/[/:\-T]/);
//   let newDT = `${dd}.${mm}.${yyyy} | ${hh}:${mi}`;
//   return newDT;
// };

// const getReformatedDate = (str) => {
//   let options = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   let startDate = new Date();
//   let endDate = new Date();
//   if (filter === 2) {
//     startDate = new Date();
//     endDate = new Date();
//   } else if (filter === 1) {
//     startDate = new Date();
//     endDate = new Date();
//   } else {
//     startDate = new Date();
//     endDate = new Date();
//   }

//   let date = str.toLocaleDateString("en-US", options);
//   return date;
// };

const getReformatedDate = (date, filter) => {
  const options = { year: "numeric", month: "long", day: "numeric" };

  let startDate;
  let endDate = new Date(); // Default to current date

  if (filter === 2) {
    // Monthly
    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1); // First day of the month
    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0); // Last day of the month
  } else if (filter === 1) {
    // Weekly
    startDate = new Date(date);
    endDate = new Date(startDate);
    startDate.setDate(startDate.getDate() - 7); // 7 days before the selected date
  } else {
    // Daily
    startDate = new Date(date); // Only the current date
  }

  const formattedStartDate = startDate.toLocaleDateString("en-US", options);
  const formattedEndDate = endDate.toLocaleDateString("en-US", options);

  return `${
    filter !== 0
      ? formattedStartDate + "-" + formattedEndDate
      : formattedStartDate
  }`;
};

const getReformatedDateTime = (str) => {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  let date = str.toLocaleDateString("en-US", options);
  return date;
};

const formatDateUTC = (dateStr) => {
  const date = new Date(dateStr);
  const isoString = date.toISOString(); // Get the UTC time as a string
  const [fullDate, time] = isoString.split("T");
  const formattedTime = time.split(".")[0]; // Get rid of milliseconds part
  return `${fullDate} ${formattedTime}`;
};

const NewReportsPdf = ({ reportsData, roomName, filter, date }) => {
  let loggedInUser = JSON.parse(localStorage.getItem("user"));

  return (
    <Document>
      <Page style={styles.body} wrap>
        {/* <Text
        style={styles.headerDate}
        render={() => getCurrentDateForHeader(new Date().toISOString())}
        fixed
      /> */}
        <View style={styles.imageView}>
          <Image src={logo} style={{ height: "auto", width: "100px" }} />
        </View>
        <View style={styles.detailBox}>
          {/* <Text style={{ ...styles.title }}>Monthly Reports</Text> */}
          <View style={styles.detailChildBox}>
            <Text style={styles.detailHeading}>{`Report Type:`}</Text>
            <Text style={styles.detailText}>
              {filter == 2 ? `Monthly` : filter == 1 ? `Weekly` : `Daily`}
            </Text>
          </View>
          <View style={{ ...styles.detailChildBox }}>
            <Text style={styles.detailHeading}>{`Rooms:`}</Text>
            <Text style={styles.detailText}>{roomName}</Text>
          </View>
          <View style={{ ...styles.detailChildBox }}>
            <Text style={styles.detailHeading}>{`Period:`}</Text>
            <Text style={styles.detailText}>
              {getReformatedDate(date, filter)}
            </Text>
          </View>
          <View style={{ ...styles.detailChildBox }}>
            <Text style={styles.detailHeading}>{`By:`}</Text>
            <Text style={styles.detailText}>
              {loggedInUser?.firstName + " " + loggedInUser?.lastName}
            </Text>
          </View>
          <View style={{ ...styles.detailChildBox }}>
            <Text style={styles.detailHeading}>{`Report Time:`}</Text>
            <Text style={styles.detailText}>
              {getReformatedDateTime(new Date())}
            </Text>
          </View>
        </View>

        <View style={{ margin: 5 }}></View>

        {/* Table Portion */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColRaisedHeader}>
              <Text style={styles.tableHeader}>Date/Time Raised</Text>
            </View>
            <View style={styles.tableColAcceptedHeader}>
              <Text style={styles.tableHeader}>Last Time</Text>
              <Text style={styles.tableHeader}>Accepted</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>
                Duration Until Accepted (Min:Sec)
              </Text>
            </View>
            <View style={styles.tableColAcceptedHeader}>
              <Text style={styles.tableHeader}>Time</Text>
              <Text style={styles.tableHeader}>Resolved</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>
                Duration Until Resolved (Min:Sec)
              </Text>
            </View>
            <View style={styles.tableColEventHeader}>
              <Text style={styles.tableHeader}>Event/Alarm</Text>
            </View>
            <View style={styles.tableColSeverityHeader}>
              <Text style={styles.tableHeader}>Severity</Text>
            </View>
            <View style={styles.tableColRoomHeader}>
              <Text style={styles.tableHeader}>Room</Text>
            </View>
            <View style={styles.tableColAcceptedByHeader}>
              <Text style={styles.tableHeader}>Last Accepted By</Text>
            </View>
          </View>
          {reportsData?.length !== 0 ? (
            reportsData?.map((item, idx) => (
              <View
                key={idx}
                style={{
                  // margin: "auto",
                  flexDirection: "row",
                  backgroundColor: getRowColor(item?.severity),
                }}
              >
                <View style={styles.tableColRaisedData}>
                  <Text style={styles.tableCell}>
                    {/* {dayjs(
                      "12-25-2001",
                      ["YYYY", "YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"],
                      true
                    ) +
                      " " +
                      dayjs(item?.modifiedAt).format("HH:mm:ss")} */}
                    {formatDateUTC(item?.createdAt)}
                  </Text>
                </View>
                <View style={styles.tableColAcceptedData}>
                  <Text style={styles.tableCell}>
                    {item?.acceptedAt &&
                      formatDateUTC(item?.acceptedAt).split(" ")[1]}
                  </Text>
                </View>
                <View style={styles.tableColDurationData}>
                  <Text style={styles.tableCell}>
                    {item?.durationUntilAccepted}
                  </Text>
                </View>
                <View style={styles.tableColAcceptedData}>
                  <Text style={styles.tableCell}>
                    {item?.resolvedAt &&
                      formatDateUTC(item?.resolvedAt).split(" ")[1]}
                  </Text>
                </View>
                <View style={styles.tableColDurationData}>
                  <Text style={styles.tableCell}>
                    {item?.durationUntilResolved}
                  </Text>
                </View>
                <View style={styles.tableColEventData}>
                  <Text style={styles.tableCell}>{item?.event}</Text>
                </View>
                <View style={styles.tableColSeverityData}>
                  <Text style={styles.tableCell}>
                    {getSeverity(item?.severity)}
                  </Text>
                </View>
                <View style={styles.tableColRoomData}>
                  <Text style={styles.tableCell}>{item?.room}</Text>
                </View>
                <View style={styles.tableColAcceptedByData}>
                  <Text style={styles.tableCell}>{item?.acceptedBy}</Text>
                </View>
              </View>
            ))
          ) : (
            <Text style={styles.tableCell}>Records Not Available</Text>
          )}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });
// Font.register({
//   family: "Poppins",
//   fonts: [
//     { src: Poppins },
//     { src: PoppinsSemiBold, fontWeight: "semibold" },
//     { src: PoppinsBold, fontWeight: "bold" },
//   ],
// });

Font.register({
  family: "Halvetica",
  fonts: [
    {
      src: Halvetica,
      fontWeight: "normal",
    },
    {
      src: HalveticaBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    padding: 35,
    paddingHorizontal: 15,
  },
  imageView: {
    height: "30px", // Try setting a fixed height
    width: "100%",
    padding: "5px",
    paddingLeft: "2px",
    // textAlign: "center",
    backgroundColor: "#0054A4",
    // justifyContent: "center", // Center the content vertically
    // alignItems: "center", // Center the content horizontally
  },
  title: {
    fontSize: 20,
    marginTop: 5,
    // marginHorizontal: "auto",
    color: "white",
    textAlign: "left",
    paddingTop: "5px",
    fontWeight: "bold",
    paddingLeft: "5px",
    paddingBottom: "4px",
    fontFamily: "Halvetica",
    backgroundColor: "#0054A4",
  },
  detailChildBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  detailHeading: {
    width: "150px",
    fontSize: 8,
    fontFamily: "Halvetica",
    fontWeight: "bold",
    margin: 0,
  },
  detailText: {
    textAlign: "right",
    fontSize: 8,
    fontFamily: "Halvetica",
    fontWeight: "normal",
    margin: 0,
  },
  author: {
    fontSize: 12,
    // textAlign: "center",
    // marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Halvetica",
    fontWeight: "normal",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  headerDate: {
    position: "absolute",
    top: 10,
    left: 0,
    right: 30,
    fontSize: 12,
    color: "grey",
    textAlign: "right",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  //TableStyles
  table: {
    // display: "table",
    // width: "auto",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },

  tableColRaisedHeader: {
    fontSize: "11px",
    width: "10%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
  },
  tableColAcceptedHeader: {
    fontSize: "9px",
    width: "8%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  tableColEventHeader: {
    fontSize: "9px",
    width: "16%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  tableColSeverityHeader: {
    fontSize: "9px",
    width: "7%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  tableColRoomHeader: {
    fontSize: "9px",
    width: "20%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  tableColAcceptedByHeader: {
    fontSize: "9px",
    width: "15%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    justifyContent: "flex-end",
  },

  tableHeader: {
    fontSize: 8,
    fontFamily: "Halvetica",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  tableCol: {
    width: "8%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    alignItems: "baseline",
  },
  tableColRaisedData: {
    width: "10%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 0,
  },
  tableColAcceptedData: {
    width: "8%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 0,
  },
  tableColDurationData: {
    width: "8%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
    padding: 0,
  },
  tableColEventData: {
    width: "16%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    padding: 0,
  },
  tableColSeverityData: {
    width: "7%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 0,
  },
  tableColRoomData: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    padding: 0,
  },
  tableColAcceptedByData: {
    width: "15%",
    borderStyle: "solid",
    borderColor: "lightgray",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    padding: 0,
  },

  tableCell: {
    fontSize: 6,
    fontFamily: "Halvetica",
    fontWeight: "normal",
  },
});

export default NewReportsPdf;
