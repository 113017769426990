import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Box,
  TextField,
} from "@mui/material";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  deviceHeading,
  outputTypographies,
  MenuProps,
  textfieldStyle,
  dialogCancelButton,
  dropdownSelect,
} from "./muiDeviceComponentStyles";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import { useDispatch, useSelector } from "../../redux/store";
import {
  getDeviceTypeDetail,
  updateDevice,
} from "../../redux/slices/DeviceSlice";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { getRoomsListForLock } from "../../redux/slices/RoomSlice";

const EditDeviceDialog = ({
  rowDeviceDetail,
  deviceTypeList,
  openEditDialog,
  handleCloseDialog,
}) => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [deviceObj, setDeviceObj] = useState({});
  const fireToasterContext = useContext(toasterContext);
  const [deviceSettings, setDeviceSettings] = useState([]);
  let { deviceDetail } = useSelector((state) => state.deviceReducer);
  let { roomsListForLocks } = useSelector((state) => state.roomReducer);
  useEffect(() => {
    dispatch(getDeviceTypeDetail(rowDeviceDetail.id));
  }, [rowDeviceDetail.id]);

  useEffect(() => {
    dispatch(getRoomsListForLock());
    setDeviceObj(rowDeviceDetail);
    setDeviceSettings(deviceDetail.deviceSettings);
  }, [deviceDetail]);

  const handleStateChange = (value, index) => {
    let deviceSettingsTemp = [];
    deviceSettingsTemp = [...deviceSettings];

    let tempVar = { ...deviceSettingsTemp[index] };
    tempVar.value = value;
    deviceSettingsTemp[index] = tempVar;
    setDeviceSettings(deviceSettingsTemp);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setDeviceObj({
      ...deviceObj,
      [event.target.name]: event.target.value,
    });
  };

  const { name, roomInfo, roomId, note, deviceTypeId } = deviceObj;

  const handleEditDevice = () => {
    setLoader(true);
    const newData = {
      name: name,
      deviceTypeId: deviceTypeId,
      roomId: roomId,
      note: note,
      deviceSettings: deviceSettings,
    };
    dispatch(updateDevice(rowDeviceDetail.id, newData, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Device edited successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };
  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openEditDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={deviceHeading} variant="deviceTableHeadingBold">
            {translate("editDevice")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={{ width: "100%" }} onSubmit={handleEditDevice}>
          <DialogContent dividers>
            <TextValidator
              sx={textfieldStyle}
              fullWidth
              id="outlined-basic"
              name="name"
              label={translate("name")}
              variant="outlined"
              type="text"
              value={name || ""}
              onChange={(e) => handleChange(e)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel id="mutiple-select-label">
                {translate("type")}
              </InputLabel>
              <Select
                labelId="mutiple-select-label"
                disabled
                name="deviceTypeId"
                sx={dropdownSelect}
                value={deviceTypeId || ""}
                onChange={(e) => handleChange(e)}
                MenuProps={MenuProps}
              >
                {deviceTypeList.map((option) => (
                  <MenuItem key={option?.deviceTypeId} value={option?.id}>
                    <Typography sx={outputTypographies} variant="List">
                      {option?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {deviceSettings?.length > 0
              ? deviceSettings?.map((item, index) => (
                  <TextValidator
                    sx={textfieldStyle}
                    fullWidth
                    key={item.id}
                    id="outlined-basic"
                    name={item.name}
                    label={item.name}
                    variant="outlined"
                    type="text"
                    value={item.value}
                    onChange={(e) => {
                      handleStateChange(e.target.value, index);
                    }}
                    InputLabelProps={{
                      style: { color: "#6D7B88" },
                    }}
                    validators={
                      item.validationRegex
                        ? ["required", `matchRegexp:${item.validationRegex}`]
                        : ["required"]
                    }
                    errorMessages={["this field is required"]}
                  />
                ))
              : null}
            {deviceTypeId === 5 ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl sx={textfieldStyle} fullWidth>
                  <SelectValidator
                    name="roomId"
                    value={roomId ? roomId : roomInfo?.id}
                    sx={{ width: "100%" }}
                    label="Room"
                    onChange={(event) => handleChange(event)}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  >
                    <MenuItem value={roomInfo?.id}>
                      <Typography
                        sx={deviceHeading}
                        variant="deviceTableCellName"
                      >
                        {roomInfo?.name}
                      </Typography>
                    </MenuItem>
                    {roomsListForLocks.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        <Typography
                          sx={deviceHeading}
                          variant="deviceTableCellName"
                          key={index}
                        >
                          {e.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Box>
            ) : null}

            <TextField
              fullWidth
              label={translate("note")}
              multiline
              rows={4}
              name="note"
              value={note || ""}
              autoFocus
              onChange={(e) => handleChange(e)}
              sx={textfieldStyle}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={dialogCancelButton}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!name || !deviceTypeId}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
                {translate("saveDevice")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default EditDeviceDialog;
