import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  profileUsers: [],
  profilesList: [],
  profileDropdown: [],
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.profileUsers = action.payload.data;
    },

    // Profile lsiting function
    getProfiles(state, action) {
      state.profilesList = action.payload.data;
    },
    getProfilesDropdown(state, action) {
      state.profileDropdown = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getUsersProfileList() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations/user-profiles`
        // { headers }
      );

      dispatch(slice.actions.getUserProfile(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setUserProfileDefault(id, handleResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations/set-default-profile?id=${id}`,
        {}
        // { headers }
      );
      handleResponse(response?.data, id);
    } catch (error) {
      console.log(error.response.data.message);
      handleResponse(error.response.data.message);
    }
  };
}

export function getProfilesList(handleProfileResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations`
        // { headers }
      );
      dispatch(slice.actions.getProfiles(response.data));
      if (handleProfileResponse) {
        handleProfileResponse();
      }
    } catch (error) {
      if (handleProfileResponse) {
        handleProfileResponse();
      }
      console.log(error);
    }
  };
}

export function getParentProfilesDropdown(parentProfileId) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations/parent-profiles-dropdown?id=${parentProfileId}`
        // { headers }
      );
      dispatch(slice.actions.getProfilesDropdown(response.data));
    } catch (error) {
      console.log("Error in getParentProfilesDropdown", error);
    }
  };
}

export function createProfile(data, handleCreateProfileResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations`,
        data
        // { headers }
      );
      handleCreateProfileResponse(response.data);
      dispatch(getProfilesList());
      dispatch(getUsersProfileList());
    } catch (error) {
      if (handleCreateProfileResponse) {
        handleCreateProfileResponse();
      }
      console.log(error);
    }
  };
}

export function updateProfile(data, handleUpdateProfileResponse) {
  console.log("UPDATE PROFILE RUN", data);
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations?id=${data.id}`,
        data
        // { headers }
      );
      handleUpdateProfileResponse(response.data);
      dispatch(getProfilesList());
      dispatch(getUsersProfileList());
    } catch (error) {
      console.log(error);
      if (handleUpdateProfileResponse) {
        handleUpdateProfileResponse();
      }
    }
  };
}

export function deleteProfile(id, handleDeleteProfileResponse) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/profileConfigurations?id=${id}`
        // { headers }
      );
      handleDeleteProfileResponse(response.data);
      dispatch(getProfilesList());
      dispatch(getUsersProfileList());
    } catch (error) {
      if (handleDeleteProfileResponse) {
        handleDeleteProfileResponse(error.response.data);
      }
      console.log(error);
    }
  };
}
