import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Avatar,
  Button,
  Container,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  textfield,
  languageHeading,
  BootstrapDialog,
  formControlWidth,
  dialogCancelButton,
  BootstrapDialogTitle,
} from "./muiLanguageComponentStyles";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import ToasterContext from "../../utils/context/toasterContext";
import {
  updateLanguage,
  uploadLanguageImage,
} from "../../redux/slices/LanguageSlice";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const EditLanguageDialog = ({
  languageDetail,
  openEditDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const hiddenFileInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const fireToasterContext = useContext(ToasterContext);
  const [createLanguageObj, setCreateLanguageObj] = useState({});

  useEffect(() => {
    setCreateLanguageObj({
      name: languageDetail?.name,
    });
  }, []);

  const handleCreateLanguage = () => {
    setLoader(true);
    // let formData = new FormData();
    // formData.append("name", name);

    // if (selectedImage) {
    //   formData.append("image", selectedImage);
    //   dispatch(updateLanguage(languageDetail?.id, formData, handleApiRes));
    // } else {
    //   dispatch(updateLanguage(languageDetail?.id, formData, handleApiRes));
    // }

    dispatch(updateLanguage(languageDetail?.id, { name: name }, handleApiRes));
  };

  const handleChange = (event) => {
    setCreateLanguageObj({
      ...createLanguageObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleClickRef = () => {
    hiddenFileInput.current.click();
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      //Icon Uploading Api dispatch
      if (selectedImage) {
        let formData = new FormData();
        formData.append("image", selectedImage);
        dispatch(uploadLanguageImage(languageDetail?.id, formData));
      }

      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Language Updated Successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const { name } = createLanguageObj;

  return (
    <Container>
      <BootstrapDialog
        // fullWidth
        open={openEditDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={languageHeading} variant="languageTableHeadingBold">
            {translate("editLanguage")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={formControlWidth} onSubmit={handleCreateLanguage}>
          <DialogContent dividers>
            <TextValidator
              sx={textfield}
              fullWidth
              name="name"
              label={translate("name")}
              variant="outlined"
              type="text"
              value={name}
              onChange={(e) => handleChange(e)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <Box
              sx={{
                pl: 1,
                width: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={languageHeading} variant="languageTableCellName">
                Upload Icon:{" "}
              </Typography>
              {selectedImage ? (
                <Avatar
                  sx={{
                    mr: 1,
                    width: 45,
                    height: 45,
                    // background: "#F5F5F5",
                    backgroundColor: "background.bgLayoutA",
                    padding: selectedImage ? "8px" : "0px",
                    backgroundImage: `url(${
                      selectedImage ? selectedImage : ""
                    })`,
                  }}
                  variant="rounded"
                  onClick={handleClickRef}
                  src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
                >
                  <UploadOutlinedIcon />
                </Avatar>
              ) : (
                <Box
                  sx={{
                    mr: 1,
                    width: 45,
                    height: 45,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // background: "#F5F5F5",
                    backgroundColor: "background.bgLayoutA",
                    padding: selectedImage ? "8px" : "0px",
                  }}
                  onClick={handleClickRef}
                >
                  <img
                    style={{ maxHeight: 34, maxWidth: 34 }}
                    src={`${languageDetail?.url}`}
                    alt="flag_image"
                    crossOrigin="anonymous"
                  />
                </Box>
              )}
              <input
                hidden
                multiple
                ref={hiddenFileInput}
                accept="image/*"
                type="file"
                onChange={(event) => {
                  handleFileInput(event);
                }}
              />
            </Box>
            {/* <img
              src={`${
                process.env.REACT_APP_HOST_API_KEY.split("api")[0]
              }images/${languageDetail?.url}`}
              alt="alternatetext"
              crossOrigin="anonymous"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={dialogCancelButton}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography
                sx={languageHeading}
                variant="languageHeadingBoldButton"
              >
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              //   disabled={!groupName}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography
                sx={languageHeading}
                variant="languageHeadingBoldButton"
              >
                {translate("editLanguage")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default EditLanguageDialog;
