import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  MenuProps,
  alarmHeading,
  alarmTypographies,
  textfield,
} from "./muiAlarmComponentStyles";
import {
  Container,
  FormControl,
  MenuItem,
  Box,
  Select,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import { getRoomsList } from "../../redux/slices/RoomSlice";
import { getProcedureList } from "../../redux/slices/procedureSlice";
import { createAlarm } from "../../redux/slices/AlarmSlice";
import toasterContext from "../../utils/context/toasterContext";
import { ValidatorForm } from "react-material-ui-form-validator";
import useLocales from "../../hooks/useLocales";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CreateDialog = ({ openDialog, handleCloseDialog }) => {
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [alarmObj, setAlarmObj] = useState({
    text: "",
    roomId: null,
    procedureId: null,
  });
  const fireToasterContext = useContext(toasterContext);

  let { roomsList } = useSelector((state) => state.roomReducer);
  let { procedureList } = useSelector((state) => state.procedureReducer);

  useEffect(() => {
    dispatch(getRoomsList());
    dispatch(getProcedureList());
  }, []);

  useEffect(() => {
    //Cleaning up states each time dialog opens

    setAlarmObj({
      text: "",
      roomId: null,
      procedureId: null,
    });
  }, [openDialog]);

  const handleGenerateAlarm = () => {
    setLoader(true);
    dispatch(createAlarm(alarmObj, handleGenerateAlarmResponse));
  };

  const handleGenerateAlarmResponse = (data) => {
    setLoader(false);
    if (!data?.status) {
      fireToasterContext.fireToasterHandler(
        false,
        data?.message ? data?.message : "Something went wrong"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        true,
        "Alert generated Successfully"
      );
      // socket.emit("generateAlarm", {
      //   title: "Alert Generated",
      //   message: data.message ? data.message : "An Alert has been generated",
      //   roomId: alarmObj.roomId,
      //   alarmId: data.alarmId,
      //   alarmSound: data.alarmSound,
      //   severity: data.severity,
      // });
    }
    handleCloseDialog();
  };

  const handleChangeAlarmData = (event) => {
    let alarmObjTemp = { ...alarmObj };
    alarmObjTemp[event.target.name] = event.target.value;

    if (event.target.name == "roomId") {
      alarmObjTemp.procedureId = null;
    }
    setAlarmObj(alarmObjTemp);
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={alarmHeading} variant="alarmTableHeadingBold">
            {translate("generateAlarm")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={{ width: "100%" }} onSubmit={handleGenerateAlarm}>
          <DialogContent dividers>
            {/* <TextValidator
              sx={textfield}
              fullWidth
              id="outlined-basic"
              label={translate("text")}
              name="text"
              variant="outlined"
              type="text"
              value={alarmObj?.firstName}
              onChange={handleChangeAlarmData}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
            /> */}

            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={textfield} fullWidth>
                <InputLabel>{translate("room")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={alarmObj?.roomId || ""}
                  sx={{ width: "100%" }}
                  label={translate("room")}
                  name="roomId"
                  onChange={handleChangeAlarmData}
                  MenuProps={MenuProps}
                >
                  {roomsList.map((e, index) => (
                    <MenuItem key={index} value={e.id}>
                      <Typography
                        sx={alarmTypographies}
                        variant="inputOutputList"
                        key={index}
                      >
                        {e.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={textfield} fullWidth>
                <InputLabel>{translate("event")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={alarmObj?.procedureId || ""}
                  sx={{ width: "100%" }}
                  label={translate("event")}
                  name="procedureId"
                  onChange={handleChangeAlarmData}
                  MenuProps={MenuProps}
                >
                  {procedureList
                    ?.filter((el) => {
                      return el.roomId == alarmObj.roomId && el.active;
                    })
                    ?.map((e, index) => (
                      <MenuItem key={index} value={e.id}>
                        <Typography
                          sx={alarmTypographies}
                          variant="inputOutputList"
                          key={index}
                        >
                          {e.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={{
                borderRadius: "10px",
                background: "black",
                "&:hover": { backgroundColor: "black" },
              }}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography sx={alarmHeading} variant="alarmTableHeadingBold">
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!alarmObj.roomId || !alarmObj.procedureId}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={alarmHeading} variant="alarmTableHeadingBold">
                {translate("generateAlarm")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default CreateDialog;
