import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  sensorList: [],
  sensorDetail: {},
  sensorIconList: [],
  sensorEventList: [],
};

const slice = createSlice({
  name: "sensors",
  initialState,
  reducers: {
    getSensors(state, action) {
      state.sensorList = action.payload.data;
    },
    getSensorDetail(state, action) {
      state.sensorDetail = action.payload.data;
    },
    getSensorIcons(state, action) {
      state.sensorIconList = action.payload.data;
    },
    getSensorEvents(state, action) {
      state.sensorEventList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getSensorList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors`
        // { headers }
      );
      dispatch(slice.actions.getSensors(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function getSensorDetails(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/${id}`
        // { headers }
      );
      dispatch(slice.actions.getSensorDetail(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getSensorIconList() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/events-icons`
        // { headers }
      );
      dispatch(slice.actions.getSensorIcons(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getEventListOfSensor(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/sensor-events-for-procedures`
        // { headers }
      );
      dispatch(slice.actions.getSensorEvents(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createSensor(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors`,
        data
        // { headers }
      );
      dispatch(getSensorList());
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function updateSensor(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors?id=${data.id}`,
        { name: data.name, sensorKey: data.sensorKey }
        // { headers }
      );
      dispatch(getSensorList());
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function updateSensorEvent(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/update-sensor-event?id=${data.id}`,
        data
        // { headers }
      );

      dispatch(getSensorDetails(data.sensorId));
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createSensorEvent(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/create-sensor-event`,
        data
        // { headers }
      );

      dispatch(getSensorDetails(data.sensorId));
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      console.log(error);
      handleApiRes(error?.response?.data);
    }
  };
}

export function deleteSensor(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors?id=${id}`
        // { headers }
      );

      dispatch(getSensorList());
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data);
      console.log(error);
    }
  };
}

export function deleteSensorEvent(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/delete-sensor-event?id=${data.id}`
        // { headers }
      );
      dispatch(getSensorDetails(data.sensorId));
      // console.log(response);
      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}

export function uploadIconForEvent(data, handleUploadIconForRoomEvent) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/upload-custom-icon`,
        data
        // { headers }
      );

      dispatch(getSensorIconList());
      if (handleUploadIconForRoomEvent)
        handleUploadIconForRoomEvent(response.data);
    } catch (error) {
      console.log(error);
      handleUploadIconForRoomEvent(error.response.data);
    }
  };
}

export function deleteIconForDeviceType(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/delete-icon?iconName=${data}`
      );
      dispatch(getSensorIconList());

      if (handleApiRes) handleApiRes(response?.data);
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes(error.response.data);
    }
  };
}
