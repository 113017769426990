import React, { useContext, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  formControlWidth,
  languageHeading,
  textfield,
} from "./muiLanguageComponentStyles";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
// import addImage from "../../assets/add-image.png";
import ToasterContext from "../../utils/context/toasterContext";
import {
  createLanguage,
  uploadLanguageImage,
} from "../../redux/slices/LanguageSlice";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const CreateLanguageDialog = ({ openCreateDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const hiddenFileInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const fireToasterContext = useContext(ToasterContext);
  const [createLanguageObj, setCreateLanguageObj] = useState({});

  const handleCreateLanguage = () => {
    setLoader(true);
    // let formData = new FormData();
    // formData.append("name", name);
    // formData.append("image", selectedImage);

    dispatch(createLanguage({ name: name }, handleApiRes));
  };

  const handleChange = (event) => {
    setCreateLanguageObj({
      ...createLanguageObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileInput = (e) => {
    // const selectedFile = event.target.files[0];
    // setFile(selectedFile);
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      // toast.success("File Selected sucessfully", toastOptions);
    } else {
      // toast.success("Something went wrong", toastOptions);
    }
  };

  const handleClickRef = () => {
    hiddenFileInput.current.click();
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      //dispatching Api for Icon Image uplaod//
      let formData = new FormData();
      formData.append("image", selectedImage);

      dispatch(uploadLanguageImage(data?.data?.id, formData));

      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Language created successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const { name } = createLanguageObj;

  return (
    <Container>
      <BootstrapDialog
        maxWidth={"md"}
        // fullWidth
        open={openCreateDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={languageHeading} variant="languageTableHeadingBold">
            {translate("createLanguage")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm
          style={formControlWidth}
          onSubmit={handleCreateLanguage}
          // onError={(errors) => console.log(errors)}
        >
          <DialogContent dividers>
            <TextValidator
              sx={textfield}
              fullWidth
              name="name"
              label={translate("name")}
              variant="outlined"
              type="text"
              value={name}
              onChange={(e) => handleChange(e)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <Box
              sx={{
                pl: 1,
                width: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={languageHeading} variant="languageTableCellName">
                Upload Icon:{" "}
              </Typography>
              <Avatar
                sx={{
                  mr: 1,
                  width: 45,
                  height: 45,
                  // background: "#F5F5F5",
                  backgroundColor: "background.bgLayoutA",
                  padding: selectedImage ? "8px" : "0px",
                  backgroundImage: `url(${selectedImage ? selectedImage : ""})`,
                }}
                variant="rounded"
                onClick={handleClickRef}
                src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
              >
                <UploadOutlinedIcon />
              </Avatar>
              <input
                hidden
                multiple
                ref={hiddenFileInput}
                accept="image/*"
                type="file"
                onChange={(event) => {
                  handleFileInput(event);
                }}
              />
            </Box>
            {/* <Box
              sx={{
                background: "#F5F5F5",
                borderRadius: 2,
                width: "300px",
                height: "300px",
                backgroundImage: `url(${selectedImage ? "" : addImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100px 100px",
                backgroundPosition: "center",
                objectFit: "contain",
                [`@media screen and (max-width: 500px)`]: {
                  width: "200px",
                  height: "200px",
                },
              }}
              onClick={handleClickRef}
            >
              <input
                hidden
                multiple
                ref={hiddenFileInput}
                accept="image/*"
                type="file"
                onChange={(event) => {
                  handleFileInput(event);
                }}
              />
              {selectedImage && (
                <div style={{ display: "flex" }}>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    style={{ maxWidth: "100%", height: "300px" }}
                    alt="Thumb"
                  />
                </div>
              )}
            </Box> */}
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={dialogCancelButton}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography
                sx={languageHeading}
                variant="languageHeadingBoldButton"
              >
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              //   disabled={!groupName}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography
                sx={languageHeading}
                variant="languageHeadingBoldButton"
              >
                {translate("createLanguage")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default CreateLanguageDialog;
