import React from "react";
import { Box, List, Drawer, ListItem, Typography } from "@mui/material";
import { sidebarDrawer, tableLoaderBox } from "./muiReportComponentStyles";
import useLocales from "../../hooks/useLocales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const ReportsDetailDrawer = ({
  openDetailsDrawer,
  setOpenDetailsDrawer,
  alarmDetail,
  rowHeader,
  yearMonthDay,
  selectedDateForWeek,
}) => {
  const { translate } = useLocales();
  const getDateString = () => {
    // let { $y, $M, $D } = selectedDateForWeek
    //   ? selectedDateForWeek
    //   : yearMonthDay;
    // let date = selectedDateForWeek
    //   ? `${$D}-${$M + 1}-${$y}`
    //   : `${$D}-${$M}-${$y}`;
    // return date;
    let [yyyy, mm, dd] =
      selectedDateForWeek && selectedDateForWeek.split(/[/:\-T]/);
    let newDT = `${dd}-${mm}-${yyyy} `;
    return newDT;
  };

  const dateTimeReformat = (dateStr) => {
    // const [yyyy, mm, dd, hh, mi, ss] = dateStr.split(/[/:\-T]/);

    // // Format date as dd-mm-yyyy
    // const newDT = `${dd}-${mm}-${yyyy} | ${hh}:${mi}:${ss}`;

    // return newDT;
    const formattedDate = dayjs(dateStr).utc().format("DD-MM-YYYY | HH:mm:ss");
    return formattedDate;
  };

  return (
    <>
      <Box>
        <Drawer
          anchor={"right"}
          variant="temporary"
          open={openDetailsDrawer}
          sx={sidebarDrawer}
          BackdropProps={{ invisible: true }}
          onClose={() => setOpenDetailsDrawer(false)}
        >
          <Box
          //   role="presentation"
          // onClick={() => setOpenDetailsDrawer(false)}
          //   onKeyDown={toggleDrawer(anchor, false)}
          >
            <List sx={sidebarDrawer}>
              <Box sx={{ ...tableLoaderBox, mb: 1 }}>
                <ListItem sx={tableLoaderBox} disablePadding>
                  <Typography sx={sidebarDrawer} variant="dateHeading">
                    {rowHeader?.length > 8
                      ? dayjs(yearMonthDay).format("DD-MM-YYYY") +
                        " | " +
                        rowHeader[alarmDetail?.idx]?.key +
                        "-" +
                        rowHeader[alarmDetail?.idx + 1]?.key
                      : selectedDateForWeek && getDateString()}
                  </Typography>
                </ListItem>
              </Box>
              {alarmDetail?.alarms?.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    mb: 2,
                    padding: 2,
                    paddingTop: 2,
                    paddingLeft: 2,
                    paddingRight: 1,
                    borderRadius: 2,
                    paddingBottom: 2,
                    background: "#F4F8FB",
                  }}
                >
                  <Box>
                    <Typography
                      sx={sidebarDrawer}
                      variant="alarmDetailTextHeading"
                    >
                      {translate("text")}
                      {": "}
                    </Typography>
                    <Typography
                      sx={sidebarDrawer}
                      variant="alarmDetailTextHeading"
                    >
                      {item?.text}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={sidebarDrawer}
                      variant="alarmDetailTextHeading"
                    >
                      {translate("status")}
                      {": "}
                    </Typography>
                    <Typography
                      sx={{
                        color:
                          item?.status === "Active"
                            ? "#EC313B"
                            : item?.status === "Accepted"
                            ? "#007AFF"
                            : "#03DE73",
                        fontSize: "12px",
                        fontFamily: "Poppins-SemiBold",
                      }}
                      variant="alarmDetailText"
                    >
                      {item?.status}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={sidebarDrawer}
                      variant="alarmDetailTextHeading"
                    >
                      {translate("raised")}
                      {": "}
                    </Typography>
                    <Typography sx={sidebarDrawer} variant="alarmDetailText">
                      {dateTimeReformat(item?.createdAt)}
                    </Typography>
                  </Box>
                  {item?.status === "Resolved" && (
                    <>
                      <Box>
                        <Typography
                          sx={sidebarDrawer}
                          variant="alarmDetailTextHeading"
                        >
                          {translate("accepted")}
                          {": "}
                        </Typography>
                        <Typography
                          sx={sidebarDrawer}
                          variant="alarmDetailText"
                        >
                          {item?.acceptedAt?.length > 1 &&
                            dateTimeReformat(item?.acceptedAt)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={sidebarDrawer}
                          variant="alarmDetailTextHeading"
                        >
                          {translate("resolved")}
                          {": "}
                        </Typography>
                        <Typography
                          sx={sidebarDrawer}
                          variant="alarmDetailText"
                        >
                          {item?.resolvedAt?.length > 1 &&
                            dateTimeReformat(item?.resolvedAt)}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {item?.status === "Accepted" && (
                    <Box>
                      <Typography
                        sx={sidebarDrawer}
                        variant="alarmDetailTextHeading"
                      >
                        {translate("accepted")}
                        {": "}
                      </Typography>
                      <Typography sx={sidebarDrawer} variant="alarmDetailText">
                        {item?.acceptedAt?.length > 1 &&
                          dateTimeReformat(item?.acceptedAt)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default ReportsDetailDrawer;
