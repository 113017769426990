import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Checkbox,
  Box,
  Stack,
  FormControlLabel,
} from "@mui/material";
import {
  checkBoxRolePermissionLabel,
  miniScrollBarStyles,
  rolePermissionHeading,
  tableHeadColor,
  tableHeadColor_minWidth,
} from "./muiRolePermissionsComponentStyles";
import { useDispatch, useSelector } from "../../redux/store";
import { getRolesList } from "../../redux/slices/RoleSlice";
import {
  getPermissionsList,
  getRolesWithPermissionsList,
  updateRolePermissions,
} from "../../redux/slices/permissionSlice";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const Index = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [rolesWithPermissions, setRolesWithPermissions] = useState([[]]);
  // const [newPermissions, setNewPermissions] = useState([]);

  let { rolesList } = useSelector((state) => state.roleReducer);
  let { permissionsList, rolesWithpermissionsList } = useSelector(
    (state) => state.permissionReducer
  );

  useEffect(() => {
    dispatch(getRolesList(handleApiRes));
    dispatch(getPermissionsList(handleApiRes));
    dispatch(getRolesWithPermissionsList(handleApiRes));
  }, []);

  const handleApiRes = (error) => {
    if (error) {
      fireToasterContext.fireToasterHandler(
        false,
        error?.response?.data?.messsage
          ? error?.response?.data?.messsage
          : "Something went wrong"
      );
    }
  };

  useEffect(() => {
    if (rolesList?.length > 0) {
      setRoles(JSON.parse(JSON.stringify(rolesList)));
    }
  }, [rolesList]);

  useEffect(() => {
    if (permissionsList?.length > 0) {
      setPermissions(JSON.parse(JSON.stringify(permissionsList)));
    }
  }, [permissionsList]);

  useEffect(() => {
    if (rolesWithpermissionsList?.length > 0) {
      setRolesWithPermissions(
        JSON.parse(JSON.stringify(rolesWithpermissionsList))
      );
    }
  }, [rolesWithpermissionsList]);

  const handleChangeRolePermission = (data, index) => {
    const temp = [...rolesWithPermissions];
    // let changeObj = [...newPermissions];

    if (index === "All") {
      // This is the "Select All" logic for a specific role
      const roleIndex = roles.findIndex((role) => role.id === data.id);
      const selectAllChecked = temp.every((perm) => perm[roleIndex]?.isAllowed);

      // Toggle all permissions for this role
      temp.forEach((permission, permissionIndex) => {
        if (temp[permissionIndex][roleIndex]) {
          temp[permissionIndex][roleIndex].isAllowed = !selectAllChecked;
        }
      });

      // changeObj.push(temp);
      // setNewPermissions(temp);
      setRolesWithPermissions(temp);
    } else {
      // Individual permission toggle logic remains the same

      const match = temp[index].findIndex((element) => element.id === data.id);
      const singlePermission = permissions.find(
        (el) => el.id === data.permissionId
      );

      if (singlePermission.parentId) {
        if (!temp[index][match].isAllowed) {
          let indexes = {};
          for (let i = 0; i < temp.length; i++) {
            if (indexes.outerIndex) break;
            for (let j = 0; j < temp[i].length; j++) {
              if (
                temp[i][j].roleId === data.roleId &&
                temp[i][j].permissionId === singlePermission.parentId
              ) {
                indexes.outerIndex = i;
                indexes.innerIndex = j;
                break;
              }
            }
          }
          temp[indexes.outerIndex][indexes.innerIndex].isAllowed = true;
          // changeObj.push(temp[indexes.outerIndex]);
        }
        temp[index][match].isAllowed = !temp[index][match].isAllowed;
        // changeObj.push(temp[index]);
        // setNewPermissions(changeObj);
        setRolesWithPermissions(temp);
      } else {
        if (temp[index][match].isAllowed) {
          let childPermissions = permissions.filter(
            (el) => el.parentId === data.permissionId
          );
          childPermissions.map((el) => {
            for (let i = 0; i < temp.length; i++) {
              for (let j = 0; j < temp[i].length; j++) {
                if (
                  temp[i][j].roleId === data.roleId &&
                  temp[i][j].permissionId === el.id
                ) {
                  temp[i][j].isAllowed = false;
                  // changeObj.push(temp[i]);
                }
              }
            }
          });
        }
        temp[index][match].isAllowed = !temp[index][match].isAllowed;
        // changeObj.push(temp[index]);
        // setNewPermissions(changeObj);
        setRolesWithPermissions(temp);
      }
    }
  };

  const handleSubmitRolePermissions = () => {
    setLoader(true);

    dispatch(
      updateRolePermissions(
        rolesWithPermissions.flat(),
        handleApiResponseForSubmitRolesPermissions
      )
    );
  };

  const handleApiResponseForSubmitRolesPermissions = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data?.messsage
          ? data?.messsage
          : "Permissions for the roles have been updated successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          overflow: "none",
          maxHeight: "80vh",
          borderRadius: "10px",
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  ...tableHeadColor,
                  position: "sticky",
                  left: 0,
                  zIndex: 6,
                }}
              >
                <Box sx={{ width: "200px" }}>
                  <Typography
                    sx={rolePermissionHeading}
                    variant="rolePermissionTableHeadingBold"
                  >
                    {translate("rolePermissions")}
                  </Typography>
                </Box>
              </TableCell>
              {roles.length > 0 &&
                roles.map((role, index) => (
                  <TableCell
                    align="center"
                    sx={tableHeadColor_minWidth}
                    key={index}
                  >
                    <Box>
                      <Typography
                        sx={rolePermissionHeading}
                        variant="rolePermissionTableHeadingBold"
                      >
                        {role.name}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Box>
                  <Typography
                    sx={rolePermissionHeading}
                    variant="rolePermissionTableCellName"
                  >
                    {translate("selectAll")}
                  </Typography>
                </Box>
              </TableCell>
              {roles.map((role) => (
                <>
                  <TableCell align="center" key={role.id} sx={{ pl: 5.3 }}>
                    <FormControlLabel
                      sx={checkBoxRolePermissionLabel}
                      control={
                        <Checkbox
                          name={role.name}
                          checked={rolesWithPermissions.every((permissionSet) =>
                            permissionSet.find(
                              (perm) =>
                                perm.roleId === role.id && perm.isAllowed
                            )
                          )}
                          onChange={() =>
                            handleChangeRolePermission(role, "All")
                          }
                        />
                      }
                    />
                  </TableCell>
                </>
              ))}
            </TableRow>
            {permissions.length > 0 &&
              permissions.map((permission, permissionIndex) => (
                <TableRow
                  sx={{
                    backgroundColor: permission.parentId
                      ? "background.bgTableOddRow"
                      : "background.bgTableEvenRow",
                  }}
                  key={permissionIndex}
                >
                  <TableCell
                    sx={{
                      paddingLeft: permission.parentId ? "30px" : undefined,
                      position: "sticky",
                      left: 0,
                      zIndex: 4,
                      backgroundColor: permission.parentId
                        ? "background.bgTableOddRow"
                        : "background.bgTableEvenRow",
                    }}
                  >
                    <Typography
                      sx={rolePermissionHeading}
                      variant={
                        !permission.parentId
                          ? "rolePermissionTableCellBoldName"
                          : "rolePermissionTableCellName"
                      }
                    >
                      {permission.name}
                    </Typography>
                  </TableCell>

                  {rolesWithPermissions[permissionIndex]?.map(
                    (data, permissionRoleIndex) => (
                      <TableCell align="center" key={permissionRoleIndex}>
                        <Checkbox
                          checked={data.isAllowed}
                          onChange={() => {
                            handleChangeRolePermission(data, permissionIndex);
                          }}
                        />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Button
          onClick={handleSubmitRolePermissions}
          disableRipple
          sx={
            loader
              ? {
                  background: "#979797",
                  borderRadius: "10px",
                  "&:hover": { backgroundColor: "#979797" },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
              : {
                  backgroundColor: "background.bgLayoutA",
                  borderRadius: "10px",
                  "&:hover": { backgroundColor: "background.bgLayoutA" },
                  "&:disabled": {
                    backgroundColor: "#979797",
                  },
                }
          }
        >
          <Typography
            sx={rolePermissionHeading}
            variant="rolePermissionTableHeadingButtonBold"
          >
            {translate("submit")}
          </Typography>
          {loader ? <CircularProgressLoader /> : ""}
        </Button>
      </Stack>
    </>
  );
};

export default Index;
