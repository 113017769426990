import axios from "axios";
import { toast } from "react-toastify";

const refreshAccessToken = async () => {
  let deviceIdentifier = localStorage.getItem("deviceIdentifier");
  try {
    let response = await instance.post(
      `${process.env.REACT_APP_HOST_API_KEY}v1/auth/access-token`,
      { deviceIdentifier }
    );

    const newToken = response.data.message.token;
    const newRefreshToken = response.data.message.refreshToken;

    instance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    instance.defaults.headers.common["refresh-token"] = newRefreshToken;

    localStorage.setItem("token", newToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    // console.log("new resp from refresh token api", newRefreshToken);

    return { newToken, newRefreshToken };
  } catch (error) {
    console.log("error in refresh token api", error);
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_KEY, // our API base URL
});

//request interceptor
instance.interceptors.request.use(
  function (config) {
    // setting headers for all api requests
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const portalDeviceUUID = localStorage.getItem("portalDeviceUUID");

    config.headers.timezoneutcoffset = new Date().getTimezoneOffset();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["portaldeviceuuid"] = portalDeviceUUID;
    } else {
      config.headers["refresh-token"] = refreshToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // console.log("originalRequest", originalRequest);
    console.log("AXIOS ERROR: ", error);
    if (error.message === "Network Error" && !originalRequest._retry) {
      originalRequest._retry = true;
      return instance(originalRequest);
    }
    if (error.response && error.response.data) {
      if (
        error.response.status === 401 ||
        error.response.statusText === "Unauthorized"
      ) {
        localStorage.clear();
        return (window.location.href = "/login");
      }
      if (
        error.response.data.name === "TokenExpiredError" ||
        error.response.data.message === "jwt expired"
        // !originalRequest._retry
      ) {
        // originalRequest._retry = true;

        localStorage.removeItem("token");

        await refreshAccessToken();

        // return instance(originalRequest);
      } else {
        toast.error(error?.response?.data?.message, {
          autoClose: 10000,
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          draggable: true,
          theme: "colored",
        });
      }
    }

    // if (error.response && error.response.data) {
    //   if (
    //     error.response.data.name === "TokenExpiredError" ||
    //     error.response.data.message === "jwt expired"
    //   ) {
    //     // localStorage.clear();

    //     if (str != error.response.data.message) {
    //       toast.error(error.response.data.message, {
    //         autoClose: 3000,
    //         position: "top-right",
    //         closeOnClick: true,
    //         hideProgressBar: false,
    //         pauseOnHover: true,
    //         progress: undefined,
    //         draggable: true,
    //         theme: "colored",
    //       });
    //     }

    //     str = error.response.data.message;

    //     setTimeout(() => {
    //       str = "";
    //     }, 500);

    //     setTimeout(() => {
    //       // window.location.href = "/";
    //     }, 3000);
    //   }
    //   // if you wanna manage all toasters from single config file
    //   // else {
    //   //   toast.error(error.response.data.message, {
    //   //     autoClose: 10000,
    //   //     position: "top-right",
    //   //     closeOnClick: true,
    //   //     hideProgressBar: false,
    //   //     pauseOnHover: true,
    //   //     progress: undefined,
    //   //     draggable: true,
    //   //     theme: "colored",
    //   //   });
    //   // }
    // }

    return Promise.reject(error);
  }
);

export default instance;
