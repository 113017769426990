import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  InputBase,
  Typography,
  Grid,
  TableCell,
  Box,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableRow,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import Loader from "../Loader/Loader";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import DeleteInputDialog from "./DeleteInputDialog";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import { getInputList, updateInputStatus } from "../../redux/slices/InputSlice";

import {
  customStyledTableRow,
  inputHeading,
  StyledTableRow,
  tableAvatar,
  tableAvatarCell,
  tableContainer,
  tableHeadColor,
  tableIconBox,
  tableIconButton,
  tableIconButtonWithMargin,
  tableLoaderBox,
  tableSearch,
  tableSearchButton,
  tableSearchIcon,
} from "./muiInputComponentStyles";
import useLocales from "../../hooks/useLocales";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContext } from "../../utils/context/PageContext";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const { page, setPage, updateActiveRoute } = useContext(PageContext);
  const location = useLocation();

  const { translate } = useLocales();
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [inputDetail, setInputDetail] = useState([]);
  //   const [openDialog, setOpenDialog] = useState(false);
  let { inputList } = useSelector((state) => state.inputReducer);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    // setLoading(true);
    dispatch(getInputList(handleApiRes));
    updateActiveRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(inputList);
    }
  }, [inputList]);

  const handleApiRes = () => {
    setLoading(false);
  };

  const handleMenuClick = (event, id) => {
    setRowId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleStatusChange = (status) => {
    dispatch(updateInputStatus(rowId, status, handleApiRes));
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleEditInputDevice = (data) => {
    navigate("/input-devices/edit-input-device", {
      state: { selectedInputDevice: data },
    });
  };
  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    inputList.map((item) => {
      const name = item.name.toString().toLowerCase();
      const deviceTypeName = item?.inputCategory?.name
        ?.toString()
        .toLowerCase();
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (deviceTypeName?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <Grid spacing={2} container>
        {localStorage.getItem("permissions")?.includes("create input") ? (
          <Grid item lg={9} md={8} sm={8} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchInputs")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        ) : (
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchInputs")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        )}
        {/* <Grid item lg={3} md={4} sm={4} xs={12}>
          <Button
            // onClick={() => navigate("/input-devices/add-input-devices")}
            disableRipple
            sx={tableSearchButton}
          >
            <Typography sx={inputHeading} variant="inputHeadingBoldButton">
              Duplilcate{" "}
            </Typography>
          </Button>
        </Grid> */}

        {localStorage.getItem("permissions")?.includes("create input") ? (
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Button
              onClick={() => navigate("/input-devices/add-input-devices")}
              disableRipple
              sx={tableSearchButton}
            >
              <Typography sx={inputHeading} variant="inputHeadingBoldButton">
                {translate("createInput")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table
          sx={{ overflowX: "auto" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell sx={tableHeadColor}>
                <Typography sx={inputHeading} variant="inputTableHeadingBold">
                  {translate("name")}
                </Typography>
              </TableCell>
              {loading ? null : inputList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={inputHeading}
                      variant="inputTableHeadingBold"
                    >
                      {translate("devices")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={inputHeading}
                      variant="inputTableHeadingBold"
                    >
                      {translate("status")}
                    </Typography>
                  </TableCell>
                  {localStorage
                    .getItem("permissions")
                    ?.includes("update input" || "delete input") ? (
                    <TableCell sx={tableHeadColor} align="right">
                      <Typography
                        sx={inputHeading}
                        variant="inputTableHeadingBold"
                      ></Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : inputList.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Typography sx={inputHeading} variant="inputTableCellName">
                    {translate("noInput")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id} sx={customStyledTableRow}>
                    <TableCell sx={tableAvatarCell} component="th" scope="row">
                      <Avatar sx={tableAvatar}>
                        {row?.name[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        sx={inputHeading}
                        variant="inputTableCellName"
                      >
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={inputHeading}
                        variant="inputTableCellName"
                      >
                        {row?.deviceConfiguration?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        disableRipple
                        variant="outlined"
                        onClick={(e) => handleMenuClick(e, row.id)}
                        sx={{ borderRadius: "10px", width: 100 }}
                      >
                        <Typography
                          sx={inputHeading}
                          variant="inputTableCellName"
                        >
                          {row?.active == true
                            ? translate("active")
                            : translate("inActive")}
                        </Typography>
                      </Button>
                    </TableCell>
                    {localStorage
                      .getItem("permissions")
                      ?.includes("update device" || "delete device") ? (
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          {localStorage
                            .getItem("permissions")
                            ?.includes("update input") ? (
                            <IconButton
                              sx={tableIconButton}
                              onClick={() => {
                                handleEditInputDevice(row);
                              }}
                            >
                              <EditOutlined sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {localStorage
                            .getItem("permissions")
                            ?.includes("delete input") ? (
                            <IconButton
                              sx={tableIconButtonWithMargin}
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setInputDetail(row);
                              }}
                            >
                              <DeleteOutlineOutlined sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        // disableScrollLock={true}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     width: 100,
        //     overflow: "visible",
        //     filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.12))",
        //   },
        // }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={() => handleStatusChange("true")}>
          <Typography sx={inputHeading} variant="inputTableCellName">
            {translate("activate")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange("false")}>
          <Typography sx={inputHeading} variant="inputTableCellName">
            {translate("deactivate")}
          </Typography>
        </MenuItem>
      </Menu>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />

      {openDeleteDialog && (
        <DeleteInputDialog
          inputDetail={inputDetail}
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          filteredList={filteredList}
          setFilteredList={setFilteredList}
        />
      )}
    </>
  );
};

export default Index;
