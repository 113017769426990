import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Paper,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  InputBase,
  TableHead,
  Typography,
  IconButton,
  TableSortLabel,
  TableContainer,
} from "@mui/material";
import {
  Search,
  EditOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";
import Loader from "../Loader/Loader";
import {
  tableSearch,
  tableIconBox,
  tableDataCell,
  tableContainer,
  tableHeadColor,
  tableLoaderBox,
  tableIconButton,
  tableNameAvatar,
  tableSearchIcon,
  procedureHeading,
  tableIconButtonWithMargin,
} from "./muiProcedureComponentStyles";
import { useNavigate } from "react-router-dom";
import useLocales from "../../hooks/useLocales";
import DeleteProcedureDialog from "./DeleteProcedureDialog";
import DuplicateProcedureDialog from "./DuplicateProcedureDialog";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import { getProcedureList } from "../../redux/slices/procedureSlice";
import { getLanguageList } from "../../redux/slices/LanguageSlice";

const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.bgTableOddRow,
  },
  "&:nth-of-type(even)": {
    background: props.theme.palette.background.bgTableEvenRow,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    background: props.theme.palette.background.bgTableRowHover,
  },
}));

const Index = () => {
  const inputRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { translate } = useLocales();
  const [page, setPage] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [procedureDetail, setProcedureDetail] = useState();

  const [sorting, setSorting] = useState({
    order: "asc",
    orderBy: "name",
  });

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(getProcedureList(handleApiRes));
  }, []);

  let { procedureList } = useSelector((state) => state.procedureReducer);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(procedureList);
    }
  }, [procedureList]);

  useEffect(() => {
    // Filter and search logic on the original list
    const filteredListTemp = procedureList.filter((item) => {
      const name = item?.name?.toString().toLowerCase();
      const roomName = item?.roomConfiguration?.name?.toString().toLowerCase();

      return (
        name?.includes(searchTerm.toLowerCase()) ||
        roomName?.includes(searchTerm.toLowerCase())
      );
    });

    // Apply sorting on the filtered list
    const sortedList = [...filteredListTemp].sort((a, b) => {
      const aValue = getProperty(a, sorting.orderBy);
      const bValue = getProperty(b, sorting.orderBy);

      if (typeof aValue === "string") {
        return sorting.order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sorting.order === "asc" ? aValue - bValue : bValue - aValue;
      }
    });

    setFilteredList(sortedList);
  }, [searchTerm, sorting, procedureList]);

  const handleRequestSort = (property) => {
    const isAsc = sorting.orderBy === property && sorting.order === "asc";
    setSorting({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });

    // Implement sorting logic on the original list
    const sortedList = [...procedureList].sort((a, b) => {
      const aValue = getProperty(a, property);
      const bValue = getProperty(b, property);

      if (typeof aValue === "string") {
        return sorting.order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sorting.order === "asc" ? aValue - bValue : bValue - aValue;
      }
    });

    setFilteredList(sortedList);
  };

  const handleSearch = (value) => {
    // Setting table page number 1
    setPage(0);
    setSearchTerm(value);
  };

  const getProperty = (object, path) => {
    const properties = path.split(".");
    return properties.reduce(
      (acc, current) => (acc ? acc[current] : undefined),
      object
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleApiRes = () => {
    setLoading(false);
  };

  const handleEditProcedure = (data) => {
    navigate("/room-events/edit-room-event", {
      state: { selectedEvent: data },
    });
  };

  const handleCloseDialog = () => {
    setDeleteDialog(false);
  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialog(false);
  };

  return (
    <>
      <Grid spacing={2} container>
        {localStorage?.getItem("permissions")?.includes("create procedure") ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchProcedure")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchProcedure")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        )}

        {/* {localStorage?.getItem("permissions")?.includes("create procedure") ? (
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Button
              disableRipple
              sx={tableSearchButton}
              onClick={() => navigate("/events/create-event")}
            >
              <Typography
                sx={procedureHeading}
                variant="procedureTableHeadingBold_Button"
              >
                {translate("createProcedure")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )} */}
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell width={"40%"} sx={tableHeadColor}>
                <TableSortLabel
                  active={sorting.orderBy === "name"}
                  direction={sorting.orderBy === "name" ? sorting.order : "asc"}
                  onClick={() => handleRequestSort("name")}
                >
                  <Typography
                    sx={procedureHeading}
                    variant="procedureTableHeadingBold"
                  >
                    {translate("name")}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              {loading ? null : procedureList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={tableHeadColor} align="left">
                    <TableSortLabel
                      sx={{
                        "&.MuiTableSortLabel-icon": {
                          color: "white",
                          backgroundColor: "white",
                        },
                      }}
                      active={sorting.orderBy === "roomConfiguration.name"}
                      direction={
                        sorting.orderBy === "roomConfiguration.name"
                          ? sorting.order
                          : "asc"
                      }
                      onClick={() =>
                        handleRequestSort("roomConfiguration.name")
                      }
                    >
                      <Typography
                        sx={procedureHeading}
                        variant="procedureTableHeadingBold"
                      >
                        {translate("room")}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  {localStorage
                    ?.getItem("permissions")
                    ?.includes("delete procedure") ||
                  localStorage
                    ?.getItem("permissions")
                    ?.split(",")
                    .includes("update procedure") ? (
                    <TableCell width={"30%"} sx={tableHeadColor} align="right">
                      {/* ... (existing code) */}
                    </TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : procedureList.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  {" "}
                  <Typography
                    sx={procedureHeading}
                    variant="procedureTableCellName_Index"
                  >
                    {translate("noProcedure")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={tableDataCell} component="th" scope="row">
                      {row?.iconUrl ? (
                        <Box
                          sx={{
                            height: "39px",
                            width: "39px",
                            minWidth: "39px",
                            display: "flex",
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "background.bgLayoutA",
                            mr: 1,
                          }}
                        >
                          <Box
                            sx={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "25px",
                            }}
                          >
                            <img
                              src={row?.iconUrl}
                              crossOrigin="anonymous"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Avatar sx={tableNameAvatar}>
                          {row?.name[0].toUpperCase()}
                        </Avatar>
                      )}
                      <Typography
                        sx={{ ...procedureHeading, ml: 0.2 }}
                        variant="procedureTableCellName_Index"
                      >
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={procedureHeading}
                        variant="procedureTableCellName_Index"
                      >
                        {row?.roomConfiguration?.name}
                      </Typography>
                    </TableCell>
                    {localStorage
                      ?.getItem("permissions")
                      ?.includes("delete procedure") ||
                    localStorage
                      ?.getItem("permissions")
                      ?.split(",")
                      ?.includes("update procedure") ? (
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          {/* <Button
                            disableRipple
                            sx={{ ...tableSearchButton, mr: 2, width: 110 }}
                            onClick={() => {
                              
                              setDuplicateDialog(true);
                              setProcedureDetail(row);
                            }}
                          >
                            <Typography
                              sx={procedureHeading}
                              variant="procedureTableHeadingBold_Button"
                            >
                              {translate("duplicate")}
                            </Typography>
                          </Button> */}

                          {localStorage
                            ?.getItem("permissions")
                            ?.split(",")
                            ?.includes("update procedure") && (
                            <IconButton
                              sx={tableIconButton}
                              onClick={() => {
                                handleEditProcedure(row);
                              }}
                            >
                              <EditOutlined sx={{ color: "white" }} />
                            </IconButton>
                          )}
                          {localStorage
                            .getItem("permissions")
                            .includes("delete procedure") ? (
                            <IconButton
                              sx={tableIconButtonWithMargin}
                              onClick={() => {
                                setDeleteDialog(true);
                                setProcedureDetail(row);
                              }}
                            >
                              <DeleteOutlineOutlined sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />

      {deleteDialog && (
        <DeleteProcedureDialog
          procedureDetail={procedureDetail}
          deleteDialog={deleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}

      {duplicateDialog && (
        <DuplicateProcedureDialog
          procedureDetail={procedureDetail}
          duplicateDialog={duplicateDialog}
          handleCloseDialog={handleCloseDuplicateDialog}
        />
      )}
    </>
  );
};

export default Index;
