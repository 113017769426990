import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Slide,
  Button,
  Table,
  Paper,
  Select,
  MenuItem,
  TableRow,
  TableCell,
  TableHead,
  Container,
  TextField,
  TableBody,
  InputLabel,
  Typography,
  FormControl,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
// import ReportsPDF from "./ReportsPDF";
import {
  MenuProps,
  textfieldStyle,
  tableContainer,
  logsTypographies,
  textfieldStyleWithMT,
  reportsCalendarHeader,
  reportsCalendarBodyCell,
  reportsCalendarBodyCell_Disabled,
  logsSearchFilterButtonWhileLoading,
  logsSearchFilterButtonWhileNotLoading,
  logsSearchFilterButton,
} from "./muiReportComponentStyles";
import ReportRows from "./ReportsRows";
import useLocales from "../../hooks/useLocales";
import ReportsDetailDrawer from "./ReportsDetailDrawer";
import { getRoomsList } from "../../redux/slices/RoomSlice";
import { useDispatch, useSelector } from "../../redux/store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getAllReportList,
  getAllReportListForPDF,
  getSensorListForReport,
} from "../../redux/slices/ReportSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { circularProgressLoader } from "../input/muiInputComponentStyles";
import DownloadPDFButton from "./DownloadPDFButton";
import { getProcedureList } from "../../redux/slices/procedureSlice";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // for saving the file
import logo from "../../assets/excel-logo.png";
// import { PDFViewer } from "@react-pdf/renderer";
// import NewReportsPdf from "./NewReportsPDF";

const testData = {
  data: [
    {
      severity: 1,
      procedures: [],
    },
    {
      severity: 2,
      procedures: [],
    },
    {
      severity: 3,
      procedures: [],
    },
  ],
  headers: [
    {
      key: "2:36PM - 8:36PM",
    },
    {
      key: "8:37PM - 2:37AM",
    },
    {
      key: "2:38AM - 8:38AM",
    },
    {
      key: "8:39AM - 2:39PM",
    },
  ],
};

// Function to convert image URL to base64
// Function to convert image URL to ArrayBuffer
const fetchImageAsArrayBuffer = async (imageUrl) => {
  const response = await fetch(imageUrl);
  if (!response.ok) throw new Error("Failed to fetch image");
  return await response.arrayBuffer();
};

const Index = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  let loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [rowId, setRowId] = useState(0);
  const [filter, setFilter] = useState(2);
  const [roomId, setRoomId] = useState("All");
  const [alarmStatus, setAlarmStatus] = useState("All");
  const [sensorEventId, setSensorEventId] = useState("All");
  const [loading, setLoading] = useState(true);
  const [alarmDetail, setAlarmDetail] = useState({});

  // const [yearMonthDay, setYearMonthDay] = useState(
  //   dayjs(new Date().toLocaleString().split(",")[0])
  // );
  const [yearMonthDay, setYearMonthDay] = useState(dayjs());
  const [selectedDateForWeek, setSelectedDateForWeek] = useState();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [animateTriger, setAnimationTriger] = useState(false);
  const [calendarAnimate, setCalendarAnimate] = useState(true);
  const [logArrayDaily, setLogArrayDaily] = useState(testData);
  const [reportRowAnimate, setReportRowAnimate] = useState(false);
  const [logArrayMonthly, setLogArrayMonthly] = useState(testData);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const datePickerLabels = [
    {
      label: translate("yearMonthDay"),
      slots: ["day", "month", "year"],
    },
    {
      label: translate("yearMonthEndDate"),
      slots: ["day", "month", "year"],
    },
    {
      label: translate("yearMonth"),
      slots: ["month", "year"],
    },
  ];

  const { roomsList } = useSelector((state) => state.roomReducer);
  const { allReportList, allReportListForPDF, sensorReportList } = useSelector(
    (state) => state.reportReducer
  );

  useEffect(() => {
    dispatch(getRoomsList());
    dispatch(
      getAllReportList(
        "All",
        "All",
        filter,
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
        "All",
        handleApiResponse
      )
    );
    dispatch(
      getAllReportListForPDF(
        "All",
        "All",
        filter,
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
        "All",
        handleApiResponse
      )
    );
    dispatch(getProcedureList());
    dispatch(getSensorListForReport());
  }, []);

  //Effect to set data in states when received by api
  // useEffect(() => {
  //   setRoomListArray(roomsList);
  // }, [roomsList]);

  //Effect to set reports data in LogArray
  useEffect(() => {
    if (filter == 1 || filter == 0) {
      setLogArrayDaily(allReportList);
    }
    if (filter == 2) setLogArrayMonthly(allReportList);
  }, [allReportList]);

  //Effect to create a animation-delay(so Table UI not break)
  //This effect to Call alarmDetails table with 1 sec delay
  useEffect(() => {
    if (Object.keys(alarmDetail)?.length >= 1) {
      setOpenDetailsDrawer(true);
      setTimeout(() => {
        setAnimationTriger(true);
      }, 2000);
    }
  }, [alarmDetail]);
  //This effect to Call back main reports detail table with 1.6 sec delay
  useEffect(() => {
    if (!animateTriger) {
      setTimeout(() => {
        setAlarmDetail({});
      }, 2600);
    }
  }, [animateTriger]);

  const handleChangeFilter = (e) => {
    setFilter(e.target.value);
    setYearMonthDay(dayjs());
  };

  const handleFilterSearchSubmit = () => {
    setLoading(true);
    // dispatch(getReportList(roomId, filter, handleApiResponse));
    dispatch(
      getAllReportList(
        alarmStatus,
        roomId,
        filter,
        yearMonthDay?.$y,
        yearMonthDay?.$M + 1,
        yearMonthDay?.$D,
        sensorEventId,
        handleApiResponse
      )
    );
    dispatch(
      getAllReportListForPDF(
        alarmStatus,
        roomId,
        filter,
        yearMonthDay?.$y,
        yearMonthDay?.$M + 1,
        yearMonthDay?.$D,
        sensorEventId,
        handleApiResponse
      )
    );
  };

  const handleCalendarCellClick = (date) => {
    setLoading(true);
    setFilter(0);
    // Ensure clickedDate is padded to two digits (e.g., "07")

    // Ensure clickedDate (day) is padded to two digits (e.g., "07")
    const day = date.toString().padStart(2, "0");

    // Correctly combine year, month, and day from yearMonthDay
    const fullDate = dayjs(`${yearMonthDay.$y}-${yearMonthDay.$M + 1}-${day}`);

    // Check if fullDate is valid
    if (!fullDate.isValid()) {
      console.error("Invalid date:", fullDate);
      return;
    }

    // Update the state with the full date
    setYearMonthDay(fullDate);

    dispatch(
      getAllReportList(
        alarmStatus,
        roomId,
        0,
        yearMonthDay?.$y,
        yearMonthDay?.$M + 1,
        date,
        sensorEventId,
        handleApiResponseCalendarCellClick
      )
    );
    dispatch(
      getAllReportListForPDF(
        alarmStatus,
        roomId,
        0,
        yearMonthDay?.$y,
        yearMonthDay?.$M + 1,
        date,
        sensorEventId,
        handleApiResponseCalendarCellClick
      )
    );
  };

  const handleApiResponseCalendarCellClick = () => {
    setLoading(false);
    setCalendarAnimate(false);
    setTimeout(() => {
      setReportRowAnimate(true);
    }, 1200);
  };

  const handleApiResponse = () => {
    setLoading(false);
    if (filter == 1 || filter == 0) {
      setCalendarAnimate(false);
      setTimeout(() => {
        setReportRowAnimate(true);
      }, 1200);
    } else if (filter == 2) {
      setReportRowAnimate(false);
      setTimeout(() => {
        setCalendarAnimate(true);
      }, 1200);
    }
  };
  const handleArrayManipulation = (orignalArr = []) => {
    if (orignalArr?.length < 1) return [];

    //An Array with 35 elements
    //Need to create an Array with structure [[7],[7],[7],[7],[7]]
    let output = [],
      count = 0;
    let temp = [];
    for (let i = 0; i < 35; i++) {
      if (count && count % 7 == 0) {
        output.push(temp);
        temp = [];
        count = 0;
      }
      temp[count] = orignalArr[i];
      count++;
    }
    output.push(temp);
    return output;
  };

  const handleCalendarRecords = (record, filter) => {
    if (filter == "low") {
      if (record?.low?.length == 0) return false;
      return record?.low?.length;
    }
    if (filter == "medium") {
      if (record?.medium?.length == 0) return false;
      return record?.medium?.length;
    }
    if (filter == "high") {
      if (record?.high?.length == 0) return false;
      else return record?.high?.length;
    }
  };

  const formatDateUTC = (dateStr) => {
    const date = new Date(dateStr);
    const isoString = date.toISOString(); // Get the UTC time as a string
    const [fullDate, time] = isoString.split("T");
    const formattedTime = time.split(".")[0]; // Get rid of milliseconds part
    return `${fullDate} ${formattedTime}`;
  };

  const getSeverity = (severity) => {
    let temp =
      severity == 3
        ? "Low"
        : severity == 2
        ? "Medium"
        : severity == 1
        ? "High"
        : severity == 4
        ? "None"
        : "Unknown";
    return temp;
  };

  const getReformatedDate = (date, filter) => {
    const options = { year: "numeric", month: "long", day: "numeric" };

    let startDate;
    let endDate = new Date(); // Default to current date

    if (filter === 2) {
      // Monthly
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1); // First day of the month
      endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0); // Last day of the month
    } else if (filter === 1) {
      // Weekly
      startDate = new Date(date);
      endDate = new Date(startDate);
      startDate.setDate(startDate.getDate() - 7); // 7 days before the selected date
    } else {
      // Daily
      startDate = new Date(date); // Only the current date
    }

    const formattedStartDate = startDate.toLocaleDateString("en-US", options);
    const formattedEndDate = endDate.toLocaleDateString("en-US", options);

    return `${
      filter !== 0
        ? formattedStartDate + "-" + formattedEndDate
        : formattedStartDate
    }`;
  };

  const getReformatedDateTime = (str) => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    let date = str.toLocaleDateString("en-US", options);
    return date;
  };

  const handleExportToExcel = async () => {
    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Convert image to base64
    const imageBuffer = await fetchImageAsArrayBuffer(logo);

    // Define image to be added
    const imageId = workbook.addImage({
      buffer: imageBuffer, // Path to your image file
      extension: "png",
    });

    // Add the image to the worksheet
    worksheet.addImage(imageId, {
      tl: { col: 0.3, row: 0.9 }, // Top-left corner of the image
      ext: { width: 220, height: 45 }, // Dimensions of the image
    });

    // Set column width and row height to fit the image
    worksheet.columns = [
      { width: 25 }, // time raised
      { width: 10 }, // time accepted
      { width: 14 }, // duration until accepted
      { width: 14 }, // time resolved
      { width: 14 }, // duration until resolved
      { width: 35 }, // event/alarm
      { width: 10 }, // severity
      { width: 35 }, // room
      { width: 25 }, // accepted by
    ];

    // Set row heights for the first two rows to fit the image
    worksheet.getRow(1).height = 45; // Adjust row height as needed

    // Define background color for the entire row
    const fillStyle = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0054A4" }, // Blue color
    };

    // Apply background color and remove borders from the row
    const applyStylesToRow = (rowNumber) => {
      const row = worksheet.getRow(rowNumber);
      const numberOfColumns = 9; // Set this to the number of columns you expect

      for (let col = 1; col <= numberOfColumns; col++) {
        const cell = row.getCell(col);
        cell.fill = fillStyle;
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      }
    };

    // Apply styles to the first and second rows
    applyStylesToRow(1);

    // "Report Type:" row with only 2 columns, merging the rest
    let reportTypeRow = worksheet.addRow([
      "Report Type:",
      filter == 2 ? `Monthly` : filter == 1 ? `Weekly` : `Daily`,
    ]);
    worksheet.mergeCells(`B${reportTypeRow.number}:I${reportTypeRow.number}`); // Merging columns C to I

    // "Room:" row with only 2 columns, merging the rest
    let roomRow = worksheet.addRow([
      "Room:",
      roomId == "All"
        ? roomId
        : roomsList?.find((item) => item?.id == roomId)?.name,
    ]);
    worksheet.mergeCells(`B${roomRow.number}:I${roomRow.number}`); // Merging columns C to I

    // "Period:" row with only 2 columns, merging the rest
    let periodRow = worksheet.addRow([
      "Period:",
      getReformatedDate(yearMonthDay, filter),
    ]);
    worksheet.mergeCells(`B${periodRow.number}:I${periodRow.number}`); // Merging columns C to I

    // "By:" row with only 2 columns, merging the rest
    let byRow = worksheet.addRow([
      "By:",
      loggedInUser?.firstName + " " + loggedInUser?.lastName,
    ]);
    worksheet.mergeCells(`B${byRow.number}:I${byRow.number}`); // Merging columns C to I

    // "Report Time:" row with only 2 columns, merging the rest
    let reportTimeRow = worksheet.addRow([
      "Report Time:",
      getReformatedDateTime(new Date()),
    ]);
    worksheet.mergeCells(`B${reportTimeRow.number}:I${reportTimeRow.number}`); // Merging columns C to I
    let addSpace = worksheet.addRow([]); // Empty row to add space between metadata and headers
    worksheet.mergeCells(`A${addSpace.number}:I${addSpace.number}`); // Merging columns A to I

    // Define headers
    const headers = [
      "Date/Time Raised",
      "Last Time Accepted",
      "Duration Until Accepted (Min:Sec)",
      "Time Resolved",
      "Duration Until Resolved (Min:Sec)",
      "Event/Alarm",
      "Severity",
      "Room",
      "Last Accepted By",
    ];

    // Add headers to the worksheet
    let headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell) => {
      cell.font = { bold: true, size: 12 }; // Set font size to 12
      cell.alignment = { wrapText: true }; // Enable text wrapping
    });

    // Add data and apply background color based on severity
    allReportListForPDF.length > 0
      ? allReportListForPDF.forEach((report) => {
          const row = worksheet.addRow([
            report.createdAt ? formatDateUTC(report.createdAt) : "",
            report?.acceptedAt
              ? formatDateUTC(report?.acceptedAt).split(" ")[1]
              : "",
            report?.durationUntilAccepted ? report?.durationUntilAccepted : "",
            report?.resolvedAt
              ? formatDateUTC(report?.resolvedAt).split(" ")[1]
              : "",
            report?.durationUntilResolved ? report?.durationUntilResolved : "",
            report?.event ? report?.event : "",
            report?.severity ? getSeverity(report?.severity) : "",
            report?.room ? report?.room : "",
            report?.acceptedBy ? report?.acceptedBy : "",
          ]);

          // Align the specific columns
          row.getCell(2).alignment = {
            horizontal: "center",
            vertical: "middle",
          }; // Time Accepted
          row.getCell(3).alignment = {
            horizontal: "right",
            vertical: "middle",
          }; // Duration Until Accepted
          row.getCell(4).alignment = {
            horizontal: "center",
            vertical: "middle",
          }; // Time Resolved
          row.getCell(5).alignment = {
            horizontal: "right",
            vertical: "middle",
          }; // Duration Until Resolved
          row.getCell(7).alignment = {
            horizontal: "center",
            vertical: "middle",
          }; // Severity

          // Apply background color based on severity
          if (report.severity === 1) {
            row.eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FEACA2" }, // Light Yellow for low severity
              };
              cell.border = {
                top: { style: "thin", color: { argb: "E1E1E1" } },
                left: { style: "thin", color: { argb: "E1E1E1" } },
                bottom: { style: "thin", color: { argb: "E1E1E1" } },
                right: { style: "thin", color: { argb: "E1E1E1" } },
              };
              cell.font = { size: 12 };
            });
          } else if (report.severity === 2) {
            row.eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCC99" }, // Light Orange for medium severity
              };
              cell.border = {
                top: { style: "thin", color: { argb: "E1E1E1" } },
                left: { style: "thin", color: { argb: "E1E1E1" } },
                bottom: { style: "thin", color: { argb: "E1E1E1" } },
                right: { style: "thin", color: { argb: "E1E1E1" } },
              };
              cell.font = { size: 12 };
            });
          } else if (report.severity === 3) {
            row.eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FBE4C5" }, // Light Red for high severity
              };
              cell.border = {
                top: { style: "thin", color: { argb: "E1E1E1" } },
                left: { style: "thin", color: { argb: "E1E1E1" } },
                bottom: { style: "thin", color: { argb: "E1E1E1" } },
                right: { style: "thin", color: { argb: "E1E1E1" } },
              };
              cell.font = { size: 12 };
            });
          } else if (report.severity === 4) {
            row.eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "DBEAFB" }, // Light Red for high severity
              };
              cell.border = {
                top: { style: "thin", color: { argb: "E1E1E1" } },
                left: { style: "thin", color: { argb: "E1E1E1" } },
                bottom: { style: "thin", color: { argb: "E1E1E1" } },
                right: { style: "thin", color: { argb: "E1E1E1" } },
              };
              cell.font = { size: 12 };
            });
          }
        })
      : worksheet.addRow(["", "", "", "", "No Data Available", "", "", "", ""]);

    // Make the first column bold
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber > 1 && rowNumber < 8) {
        // Skip the metadata rows and header row
        const cell = row.getCell(1);
        cell.font = { bold: true };
        row.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
      }
    });

    // Write the Excel file and download it
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Report_${new Date().toISOString()}.xlsx`);
  };

  return (
    <>
      <Box>
        <ReportsDetailDrawer
          openDetailsDrawer={openDetailsDrawer}
          setOpenDetailsDrawer={setOpenDetailsDrawer}
          alarmDetail={alarmDetail}
          rowHeader={logArrayDaily?.headers}
          yearMonthDay={yearMonthDay}
          selectedDateForWeek={selectedDateForWeek}
        />
        <Grid container spacing={1}>
          {/* room select grid */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <FormControl sx={textfieldStyleWithMT} fullWidth>
              <InputLabel id="mutiple-select-label">
                {translate("room")}
              </InputLabel>
              <Select
                // size="small"
                label="Room:"
                name="roomTypeId"
                value={roomId || ""}
                onChange={(e) => setRoomId(e.target.value)}
                MenuProps={MenuProps}
              >
                <MenuItem key={0} value={"All"}>
                  <Typography sx={logsTypographies} variant="List">
                    All
                  </Typography>
                </MenuItem>
                {roomsList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <Typography sx={logsTypographies} variant="List">
                      {option?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* filter select grid */}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <FormControl sx={textfieldStyleWithMT} fullWidth>
              <InputLabel id="mutiple-select-label">
                {translate("filter")}
              </InputLabel>
              <Select
                // size="small"
                label="Filter"
                name="filter"
                // sx={dropdownSelect}
                value={filter}
                onChange={(e) => handleChangeFilter(e)}
                MenuProps={MenuProps}
              >
                {[
                  translate("daily"),
                  translate("weekly"),
                  translate("monthly"),
                ].map((option, index) => (
                  <MenuItem key={index} value={index}>
                    <Typography sx={logsTypographies} variant="List">
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* filter based date selection grid */}
          {filter == 0 && (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={textfieldStyle}
                  components={["DatePicker", "DatePicker"]}
                >
                  <FormControl onClick={() => setOpenCalendar(true)} fullWidth>
                    <DatePicker
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      views={datePickerLabels[filter]?.slots}
                      label={datePickerLabels[filter]?.label}
                      value={yearMonthDay}
                      format="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setYearMonthDay(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </FormControl>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          )}
          {filter == 1 && (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={textfieldStyle}
                  components={["DatePicker", "DatePicker"]}
                >
                  <FormControl onClick={() => setOpenCalendar(true)} fullWidth>
                    <DatePicker
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      views={datePickerLabels[filter]?.slots}
                      label={datePickerLabels[filter]?.label}
                      format="DD-MM-YYYY"
                      value={yearMonthDay}
                      onChange={(newValue) => setYearMonthDay(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </FormControl>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          )}
          {filter == 2 && (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={textfieldStyle}
                  components={["DatePicker", "DatePicker"]}
                >
                  <FormControl onClick={() => setOpenCalendar(true)} fullWidth>
                    <DatePicker
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      views={datePickerLabels[filter]?.slots}
                      label={datePickerLabels[filter]?.label}
                      format="MM-YYYY"
                      value={yearMonthDay}
                      onChange={(newValue) => setYearMonthDay(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </FormControl>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          )}
          {/* Dummy Grids */}
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <FormControl sx={{ ...textfieldStyleWithMT, mt: "4px" }} fullWidth>
              <InputLabel>{translate("status")}</InputLabel>
              <Select
                name="alarmStatus"
                value={alarmStatus || ""}
                MenuProps={MenuProps}
                label={translate("status")}
                onChange={(e) => setAlarmStatus(e.target.value)}
              >
                <MenuItem key={0} value={"All"}>
                  <Typography sx={logsTypographies} variant="List">
                    All
                  </Typography>
                </MenuItem>
                <MenuItem key={1} value={"Active"}>
                  <Typography sx={logsTypographies} variant="List">
                    Active
                  </Typography>
                </MenuItem>
                <MenuItem key={2} value={"Accepted"}>
                  <Typography sx={logsTypographies} variant="List">
                    Accepted
                  </Typography>
                </MenuItem>
                <MenuItem key={3} value={"Resolved"}>
                  <Typography sx={logsTypographies} variant="List">
                    Resolved
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <FormControl sx={{ ...textfieldStyleWithMT, mt: "4px" }} fullWidth>
              <InputLabel>{translate("roomEvents")}</InputLabel>
              <Select
                name="event"
                value={sensorEventId || ""}
                MenuProps={MenuProps}
                label={translate("roomEvents")}
                onChange={(e) => setSensorEventId(e.target.value)}
              >
                <MenuItem key={0} value={"All"}>
                  <Typography sx={logsTypographies} variant="List">
                    All
                  </Typography>
                </MenuItem>
                {sensorReportList.map((sensor) => (
                  <MenuItem
                    key={sensor.sensorEventId}
                    value={sensor.sensorEventId}
                  >
                    <Typography sx={logsTypographies} variant="List">
                      {sensor.eventName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <Button
              disabled={loading}
              type="submit"
              disableElevation
              variant="contained"
              sx={
                loading
                  ? {
                      ...logsSearchFilterButtonWhileLoading,
                    }
                  : { ...logsSearchFilterButtonWhileNotLoading }
              }
              onClick={handleFilterSearchSubmit}
            >
              {loading ? (
                <CircularProgress size={24} sx={circularProgressLoader} />
              ) : (
                translate("apply")
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            {allReportListForPDF?.length >= 0 ? (
              <DownloadPDFButton
                reportsData={allReportListForPDF}
                loading={loading}
                roomName={
                  roomId == "All"
                    ? roomId
                    : roomsList?.find((item) => item?.id == roomId)?.name
                }
                filter={filter}
                date={yearMonthDay}
              />
            ) : (
              <Button
                disableRipple
                disabled={loading}
                disableElevation
                variant="contained"
                sx={logsSearchFilterButton}
              >
                Loading...
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            {allReportListForPDF?.length >= 0 ? (
              <Button
                disableRipple
                disabled={loading}
                disableElevation
                variant="contained"
                sx={logsSearchFilterButton}
                onClick={handleExportToExcel}
              >
                {translate("exportToExcel")}
              </Button>
            ) : (
              <Button
                disableRipple
                disabled={loading}
                disableElevation
                variant="contained"
                sx={logsSearchFilterButton}
              >
                Loading...
              </Button>
            )}
          </Grid>
        </Grid>
        {/* Calender table */}

        {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
          <NewReportsPdf
            reportsData={allReportListForPDF}
            // loading={loading}
            roomName={
              roomId == "All"
                ? roomId
                : roomsList?.find((item) => item?.id == roomId)?.name
            }
            filter={filter}
            date={yearMonthDay}
          />
        </PDFViewer> */}
        {logArrayMonthly?.data?.length > 1 && (
          <Slide
            direction="left"
            in={calendarAnimate && !loading}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <TableContainer sx={{ ...tableContainer }} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("sunday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("monday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("tuesday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("wednesday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("thursday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("friday")}
                    </TableCell>
                    <TableCell sx={reportsCalendarHeader}>
                      {translate("saturday")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleArrayManipulation(logArrayMonthly?.data).map(
                    (item, idx1) => (
                      <TableRow key={idx1}>
                        {item?.map((obj, idx2) => (
                          <TableCell
                            key={idx2}
                            onClick={() => handleCalendarCellClick(obj?.date)}
                            sx={
                              obj?.isDisabled
                                ? reportsCalendarBodyCell_Disabled
                                : reportsCalendarBodyCell
                            }
                          >
                            {/* Second Style */}
                            <Container
                              disableGutters
                              sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                padding: 0,
                              }}
                            >
                              {/* Date Box */}
                              <Box
                                sx={{
                                  mt: 1,
                                  width: 15,
                                  height: 15,
                                }}
                              >
                                {obj?.date}
                              </Box>
                              {/* Alerts Box */}
                              {!obj?.isDisabled && (
                                <Box
                                  sx={{
                                    mb: "10px",
                                    width: "auto",
                                    // fontFamily: "Poppins-SemiBold",
                                  }}
                                >
                                  {handleCalendarRecords(
                                    obj?.record,
                                    "low"
                                  ) && (
                                    <Box
                                      sx={{
                                        width: "fit-content",
                                        color: "white",
                                        fontSize: 11,
                                        borderRadius: "10px",
                                        background: "#FBA452",
                                        mb: "4px",
                                        pt: "1px",
                                        pb: "1px",
                                        pl: 1,
                                        pr: 1,
                                      }}
                                    >
                                      Low{" "}
                                      {handleCalendarRecords(
                                        obj?.record,
                                        "low"
                                      )}
                                    </Box>
                                  )}
                                  {handleCalendarRecords(
                                    obj?.record,
                                    "medium"
                                  ) && (
                                    <Box
                                      sx={{
                                        width: "fit-content",
                                        color: "white",
                                        fontSize: 11,
                                        borderRadius: "10px",
                                        background: "#FA7D09",
                                        mb: "4px",
                                        pt: "1px",
                                        pb: "1px",
                                        pl: 1,
                                        pr: 1,
                                      }}
                                    >
                                      Medium{" "}
                                      {handleCalendarRecords(
                                        obj?.record,
                                        "medium"
                                      )}
                                    </Box>
                                  )}
                                  {handleCalendarRecords(
                                    obj?.record,
                                    "high"
                                  ) && (
                                    <Box
                                      sx={{
                                        width: "fit-content",
                                        color: "white",
                                        fontSize: 11,
                                        borderRadius: "10px",
                                        background: "#EC313B",
                                        pt: "1px",
                                        pb: "1px",
                                        pl: 1,
                                        pr: 1,
                                      }}
                                    >
                                      High{" "}
                                      {handleCalendarRecords(
                                        obj?.record,
                                        "high"
                                      )}
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Container>
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Slide>
        )}
        {/* Calling Three Reports Rows here */}
        <Slide
          direction="left"
          in={reportRowAnimate}
          timeout={1000}
          mountOnEnter
          unmountOnExit
        >
          {Object.keys(logArrayDaily)?.length >= 1 && (
            <Box
              sx={{
                overflow: "auto",
                width: "100%",
                mt: "40px",
                borderRadius: "10px",
              }}
            >
              <ReportRows
                key={2}
                rowData={logArrayDaily?.data[0]}
                rowHeader={logArrayDaily?.headers}
                setAlarmDetail={setAlarmDetail}
                defaultOpenCollapsable={rowId - 1 == 0 ? true : false}
                loading={false}
                rowId={rowId}
                setRowId={setRowId}
                setSelectedDateForWeek={setSelectedDateForWeek}
              />
              <ReportRows
                key={1}
                rowData={logArrayDaily?.data[1]}
                rowHeader={logArrayDaily?.headers}
                setAlarmDetail={setAlarmDetail}
                defaultOpenCollapsable={rowId - 1 == 1 ? true : false}
                loading={false}
                rowId={rowId}
                setRowId={setRowId}
                setSelectedDateForWeek={setSelectedDateForWeek}
              />
              <ReportRows
                key={0}
                rowData={logArrayDaily?.data[2]}
                rowHeader={logArrayDaily?.headers}
                setAlarmDetail={setAlarmDetail}
                defaultOpenCollapsable={rowId - 1 == 2 ? true : false}
                loading={false}
                rowId={rowId}
                setRowId={setRowId}
                setSelectedDateForWeek={setSelectedDateForWeek}
              />
            </Box>
          )}
        </Slide>
        {/* Old Reports Detail Component */}
      </Box>
    </>
  );
};

export default Index;
