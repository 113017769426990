import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  // tableCell,
  tableCollapseCell,
  // tableRow,
  checkBoxLabel,
  tableCellName,
  alarmRoomTableCell,
  // tableCellWarningAndDate,
} from "./AlarmStyle";
import {
  BedOutlined,
  // Circle,
  DeleteOutlineOutlined,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  SingleBed,
  Warning,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import useLocales from "../../hooks/useLocales";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useDispatch } from "../../redux/store";
import Loader from "../Loader/Loader";
import {
  clearAlarmsProcedure,
  deleteRoomAlarm,
  getAlarmsProcedureList,
} from "../../redux/slices/AlarmSlice";
import ToasterContext from "../../utils/context/toasterContext";
import { updateAlarmsProcedure } from "../../redux/slices/procedureSlice";
import {
  cancelModalButtonsRoom,
  deleteIconButtonsRoom,
  editIconButtonsRoom,
} from "../room/RoomStyle";
import { useNavigate } from "react-router-dom";
import MuteRoomDialog from "../room/MuteRoomDialog";
import { getRoomCreationInputList } from "../../redux/slices/InputSlice";
import { getRoomCreationOutputList } from "../../redux/slices/OutputSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function AlarmRow(props) {
  const {
    index,
    socket,
    alarm,
    setDetailAlarmID,
    openProcedureAlarm,
    setOpenProcedureAlarm,
    alarmsProcedureData,
    setAlarmsProcedureData,
    openAlarmsProcedureLoader,
    setOpenAlarmsProcedureLoader,
    handleGetAlarmsProcedureResponse,
  } = props;

  const dispatch = useDispatch();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const fireToasterContext = useContext(ToasterContext);
  const [loaderClearButton, setLoaderClearButton] = useState(false);
  //   const [openProcedureAlarm, setOpenProcedureAlarm] = useState(null);
  const [loaderUpdateProcedureButton, setLoaderUpdateProcedureButton] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openMuteModal, setOpenMuteModal] = useState(false);
  const [roomDetail, setRoomDetail] = useState();
  const [loader, setLoader] = useState(false);

  const permissionsArray = localStorage.getItem("permissions");

  const handleOpenAlarmProcedure = (id) => {
    if (openProcedureAlarm == id) {
      setOpenProcedureAlarm(null);
      return;
    }
    setOpenAlarmsProcedureLoader(true);
    dispatch(getAlarmsProcedureList(id, handleGetAlarmsProcedureResponse));
    setOpenProcedureAlarm(id);
    setDetailAlarmID(id);
  };

  const handleClearAlarmProcedure = (id, alarmId) => {
    setLoaderClearButton(true);
    dispatch(
      clearAlarmsProcedure(
        id,
        alarmId,
        handleClearAlarmProcedureResponse,
        handleGetAlarmsProcedureResponse
      )
    );
  };

  const handleClearAlarmProcedureResponse = (data, roomId) => {
    setLoaderClearButton(false);
    if (data.status) {
      setOpenProcedureAlarm(null);
      fireToasterContext.fireToasterHandler(
        true,
        "Alert Resolved Successfully"
      );
      socket.emit("resolveAlarm", {
        title: "Alert Resolved",
        message: data.message ? data.message : "An Alert Resolved successfully",
        roomId: roomId,
      });
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleChangeProcedure = (event, index) => {
    let alarmsProcedureDataTemp = JSON.parse(
      JSON.stringify(alarmsProcedureData)
    );

    //3 cases Select all row
    if (index == "All") {
      //   const flag = alarmsProcedureDataTemp?.some(
      //     (item) => item[event.target.name]
      //   );

      const flag = handleCheckboxTickChecker(event);

      alarmsProcedureData?.map((item, idx) => {
        alarmsProcedureDataTemp[idx][`${event.target.name}`] = flag
          ? false
          : true;
      });
      setAlarmsProcedureData(alarmsProcedureDataTemp);

      return;
    }

    alarmsProcedureDataTemp[index][`${event.target.name}`] =
      !alarmsProcedureDataTemp[index][event.target.name];
    setAlarmsProcedureData(alarmsProcedureDataTemp);
  };

  const handleCheckboxTickChecker = (event) => {
    const flag = alarmsProcedureData?.every((item) => item[event.target.name]);
    return flag;
  };

  const handleUpdateProcedureSettings = () => {
    setLoaderUpdateProcedureButton(true);
    let finalProceduresToBeUpdated = [];

    alarmsProcedureData.forEach((element) => {
      finalProceduresToBeUpdated.push({
        id: element.id,
        active: element.active,
        activeDuringDay: element.activeDuringDay,
        activeDuringNight: element.activeDuringNight,
      });
    });
    dispatch(
      updateAlarmsProcedure(
        finalProceduresToBeUpdated,
        handleUpdateProcedureSettingsResponse
      )
    );
  };

  const handleUpdateProcedureSettingsResponse = (data) => {
    setLoaderUpdateProcedureButton(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Update procedure Successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const dateTimeReformat = (dateStr) => {
    // const [yyyy, mm, dd, hh, mi, ss] = dateStr.split(/[/:\-T]/);
    // const newDT = `${dd}-${mm}-${yyyy} | ${hh}:${mi} : ${ss}`;
    // return newDT;
    const formattedDate = dayjs(dateStr).utc().format("DD-MM-YYYY | HH:mm:ss");
    return formattedDate;
  };

  const handleEditModal = (data) => {
    navigate(`/rooms/edit-room/${data.id}`);
  };

  const handleDeleteModal = (room) => {
    setRoomDetail(room);
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleDeleteRoom = () => {
    setLoader(true);
    dispatch(deleteRoomAlarm(roomDetail.id, handleDeleteRoomResponse));
  };

  const handleDeleteRoomResponse = (data) => {
    setLoader(false);
    setOpenDeleteModal(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Room Deleted"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      setOpenDeleteModal(false);
    }
    dispatch(getRoomCreationInputList(null));
    dispatch(getRoomCreationOutputList(null));
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor:
            index % 2
              ? "background.bgTableEvenRow"
              : "background.bgTableOddRow",
          "&.MuiTableRow-root:hover": {
            backgroundColor: "background.bgTableRowHover",
          },
        }}
        // onClick={() => handleOpenAlarmProcedure(alarm.id)}
      >
        <TableCell sx={tableCellName} align="left">
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "16px",
              color: "primary.tableFontColor",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {/* <Avatar
                sx={{
                  backgroundColor:
                    alarm?.alarm?.status === "Active"
                      ? "#EC313B"
                      : alarm?.alarm?.status === "Accepted"
                      ? "#007AFF"
                      : "background.bgLayoutA",
                  color: "white",
                }}
              >
                <BedOutlined />
              </Avatar> */}
            <Box
              sx={{
                height: "39px",
                width: "39px",
                minWidth: "39px",
                display: "flex",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  alarm?.alarm?.status === "Active" &&
                  alarm?.alarm?.severity !== 4
                    ? "background.bgLayoutB"
                    : alarm?.alarm?.status === "Accepted"
                    ? "#007AFF"
                    : "background.bgLayoutA",
              }}
            >
              {alarm?.alarm ? (
                alarm?.alarm?.procedureConfiguration?.iconUrl ? (
                  <Box
                    sx={{
                      height: "24px",
                      width: "24px",
                      borderRadius: "25px",
                    }}
                  >
                    <img
                      src={alarm?.alarm?.procedureConfiguration?.iconUrl}
                      crossOrigin="anonymous"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                ) : (
                  <BedOutlined sx={{ color: "white" }} />
                )
              ) : (
                <BedOutlined sx={{ color: "white" }} />
              )}
            </Box>
            <span
              style={{
                color:
                  alarm?.alarm?.status === "Active" &&
                  alarm?.alarm?.severity !== 4
                    ? "#EC313B"
                    : alarm?.alarm?.status === "Accepted"
                    ? "#007AFF"
                    : "",
              }}
            >
              {alarm?.name}
            </span>
            <IconButton
              onClick={() => {
                setOpenMuteModal(true), setRoomDetail(alarm);
              }}
            >
              {alarm?.suppressed == true ? (
                <VolumeOff sx={{ color: "background.bgLayoutB" }} />
              ) : (
                <VolumeUp />
              )}
            </IconButton>
          </div>
          {/* {alarm.name} */}
        </TableCell>

        <TableCell sx={alarmRoomTableCell} align="left">
          <Typography>{alarm?.defaultInput?.name}</Typography>
        </TableCell>
        {/* <TableCell sx={alarmRoomTableCell} align="left">
          <Typography>{alarm?.defaultOutput?.name}</Typography>
        </TableCell> */}

        <TableCell align="right">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            {localStorage?.getItem("permissions")?.includes("update room") && (
              <IconButton
                sx={editIconButtonsRoom}
                onClick={() => {
                  handleEditModal(alarm);
                }}
              >
                <EditOutlined />
              </IconButton>
            )}
            {localStorage?.getItem("permissions")?.includes("delete room") && (
              <IconButton
                sx={deleteIconButtonsRoom}
                onClick={() => handleDeleteModal(alarm)}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            )}

            <IconButton
              disableRipple
              size="small"
              sx={{
                "&.MuiIconButton-root": {
                  bgcolor: "lightgray",
                },
              }}
              onClick={() => handleOpenAlarmProcedure(alarm.id)}
            >
              {openProcedureAlarm == alarm.id ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse
            in={openProcedureAlarm == alarm.id ? true : false}
            timeout="auto"
            unmountOnExit
          >
            <Table size="medium" sx={{ minWidth: 900 }}>
              <TableBody>
                {openAlarmsProcedureLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : alarmsProcedureData.length < 1 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Poppins-semibold",
                      height: 70,
                    }}
                  >
                    {translate("noAlarmProcedure")}
                  </div>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={2}></TableCell>
                      <TableCell align="right" sx={tableCollapseCell}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FormControlLabel
                            sx={checkBoxLabel}
                            control={
                              <Checkbox
                                name="activeDuringDay"
                                checked={alarmsProcedureData?.every(
                                  (item) => item["activeDuringDay"]
                                )}
                                onChange={(event) =>
                                  handleChangeProcedure(event, "All")
                                }
                              />
                            }
                            label={translate("selectAll")}
                          />
                          <FormControlLabel
                            sx={checkBoxLabel}
                            control={
                              <Checkbox
                                name="activeDuringNight"
                                checked={alarmsProcedureData?.every(
                                  (item) => item["activeDuringNight"]
                                )}
                                onChange={(event) =>
                                  handleChangeProcedure(event, "All")
                                }
                              />
                            }
                            label={translate("selectAll")}
                          />
                          <FormControlLabel
                            sx={checkBoxLabel}
                            control={
                              <Switch
                                name="active"
                                checked={alarmsProcedureData?.every(
                                  (item) => item["active"]
                                )}
                                onChange={(event) =>
                                  handleChangeProcedure(event, "All")
                                }
                              />
                            }
                            label={translate("selectAll")}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                    {alarmsProcedureData.map((alarmsProcedure, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" sx={tableCollapseCell}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {/* <span> */}
                            <Box
                              sx={{
                                height: "39px",
                                width: "39px",
                                minWidth: "39px",
                                display: "flex",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:
                                  alarmsProcedure?.alarm?.status === "Active" &&
                                  alarmsProcedure?.alarm?.severity !== 4
                                    ? "background.bgLayoutB"
                                    : alarmsProcedure?.alarm?.status ===
                                      "Accepted"
                                    ? "#007AFF"
                                    : "background.bgLayoutA",
                              }}
                            >
                              {/* {alarmsProcedure?.alarm ? ( */}
                              {alarmsProcedure?.iconUrl ? (
                                <Box
                                  sx={{
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "25px",
                                  }}
                                >
                                  <img
                                    src={alarmsProcedure?.iconUrl}
                                    crossOrigin="anonymous"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <SingleBed sx={{ color: "white" }} />
                              )}
                            </Box>
                            <span>{alarmsProcedure?.name}</span>
                            {alarmsProcedure?.alarm?.acceptedBy?.firstName && (
                              <span style={{ fontStyle: "italic" }}>
                                Accepted By:&nbsp;
                                {alarmsProcedure?.alarm?.accetedByUserName}
                              </span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell sx={tableCollapseCell}>
                          {alarmsProcedure?.alarm &&
                          alarmsProcedure.alarm?.severity !== 4 ? (
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "14px",
                                color: "primary.tableFontColor",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                width: "100%",
                                // display:
                                //   alarmsProcedure?.alarm?.status === "Active" &&
                                //   alarmsProcedure?.alarm?.severity !== 4 &&
                                //   "none",
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  width: "100%",
                                }}
                              >
                                <Warning
                                  sx={{
                                    color:
                                      alarm?.alarm?.status === "Active"
                                        ? "background.bgLayoutB"
                                        : alarm?.alarm?.status === "Accepted"
                                        ? "#007AFF"
                                        : "#03DE73",
                                  }}
                                />
                                <span
                                  style={{
                                    color:
                                      alarm?.alarm?.status === "Active"
                                        ? "#EC313B"
                                        : alarm?.alarm?.status === "Accepted"
                                        ? "#007AFF"
                                        : "#03DE73",
                                  }}
                                >
                                  {dateTimeReformat(
                                    alarmsProcedure?.alarm?.createdAt
                                  )}
                                </span>
                                <br></br>
                                {alarmsProcedure.alarm?.text}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="right" sx={tableCollapseCell}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <FormControlLabel
                              sx={checkBoxLabel}
                              control={
                                <Checkbox
                                  name="activeDuringDay"
                                  checked={alarmsProcedure.activeDuringDay}
                                  onChange={(event) =>
                                    handleChangeProcedure(event, index)
                                  }
                                />
                              }
                              label={translate("activeDay")}
                            />
                            <FormControlLabel
                              sx={checkBoxLabel}
                              control={
                                <Checkbox
                                  name="activeDuringNight"
                                  checked={alarmsProcedure.activeDuringNight}
                                  onChange={(event) =>
                                    handleChangeProcedure(event, index)
                                  }
                                />
                              }
                              label={translate("activeNight")}
                            />
                            <FormControlLabel
                              sx={checkBoxLabel}
                              control={
                                <Switch
                                  name="active"
                                  checked={alarmsProcedure.active}
                                  onChange={(event) =>
                                    handleChangeProcedure(event, index)
                                  }
                                />
                              }
                              label={translate("procedureActive")}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            {alarmsProcedureData.length > 0 ? (
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  gap: alarm.alarm ? "10px" : "0px",
                }}
              >
                {alarm.alarm ? (
                  permissionsArray.includes("clear alarms") ? (
                    <Button
                      type="submit"
                      onClick={() =>
                        handleClearAlarmProcedure(alarm.id, alarm?.alarm?.id)
                      }
                      sx={
                        loaderClearButton
                          ? {
                              fontWeight: "bold",
                              fontFamily: "Poppins",
                              color: "white",
                              background: "#979797",
                              borderRadius: "10px",
                              "&:hover": { backgroundColor: "#979797" },
                              "&:disabled": {
                                backgroundColor: "#979797",
                              },
                            }
                          : {
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              color: "white",
                              backgroundColor: "background.bgLayoutB",
                              borderRadius: "10px",
                              "&:hover": {
                                backgroundColor: "background.bgLayoutB",
                              },
                              "&:disabled": {
                                backgroundColor: "#979797",
                              },
                            }
                      }
                    >
                      {translate("clearAlarms")}
                      {loaderClearButton ? <CircularProgressLoader /> : ""}
                    </Button>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {localStorage
                  .getItem("permissions")
                  .includes("update procedure settings") ? (
                  <Button
                    type="submit"
                    sx={
                      loaderUpdateProcedureButton
                        ? {
                            color: "white",
                            background: "#979797",
                            borderRadius: "10px",
                            "&:hover": { backgroundColor: "#979797" },
                            "&:disabled": {
                              backgroundColor: "#979797",
                            },
                          }
                        : {
                            color: "white",
                            background: "#0054A4",
                            borderRadius: "10px",
                            fontFamily: "Poppins-semibold",
                            "&:hover": { backgroundColor: "#0054A4" },
                            "&:disabled": {
                              backgroundColor: "#979797",
                            },
                          }
                    }
                    onClick={() => handleUpdateProcedureSettings()}
                    // disabled={}
                  >
                    {translate("updateProcedure")}
                    {loaderUpdateProcedureButton ? (
                      <CircularProgressLoader />
                    ) : (
                      ""
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      {openDeleteModal && (
        <Dialog
          sx={{ borderRadius: "10px" }}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          fullWidth
        >
          <DialogTitle
            sx={{
              backgroundColor: "#0054A4",
              color: "white",
              fontFamily: "Poppins-semibold",
              fontSize: "20px",
            }}
          >
            {translate("deleteRoom")}
          </DialogTitle>

          <DialogContent dividers>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "14px" }}
            >
              {translate("areYouSure")}
              <b>&quot;{roomDetail.name}&quot;</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              sx={cancelModalButtonsRoom}
              onClick={() => setOpenDeleteModal(false)}
            >
              {translate("cancel")}
            </Button>
            <Button
              type="submit"
              onClick={handleDeleteRoom}
              sx={
                loader
                  ? {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      background: "#EC313B",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#EC313B" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              {translate("deleteRoom")}
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openMuteModal && (
        <MuteRoomDialog
          roomDetail={roomDetail}
          openMuteModal={openMuteModal}
          setOpenMuteModal={setOpenMuteModal}
        />
      )}
    </React.Fragment>
  );
}

export default AlarmRow;
