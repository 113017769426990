import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  dialogCancelButton,
  dialogDeleteButton,
  inputHeading,
} from "./muiInputComponentStyles";
import { useDispatch } from "../../redux/store";
import { deleteInput } from "../../redux/slices/InputSlice";
import toasterContext from "../../utils/context/toasterContext";
import useLocales from "../../hooks/useLocales";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteInputDialog = ({
  inputDetail,
  openDeleteDialog,
  handleCloseDialog,
  page,
  setPage,
  rowsPerPage,
  filteredList,
  setFilteredList,
}) => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleDeleteInput = () => {
    setLoader(true);
    dispatch(deleteInput(inputDetail.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    handleCloseDialog();
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Input deleted successfully"
      );

      // Check if we need to go back a page after deletion
      const newFilteredList = filteredList.filter(
        (input) => input.id !== inputDetail.id
      );
      const totalPages = Math.ceil(newFilteredList.length / rowsPerPage);

      // If there are no more items on the current page and we're not on the first page, go back to the previous page
      if (page >= totalPages && page > 0) {
        setPage(page - 1);
      }

      setFilteredList(newFilteredList);
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={inputHeading} variant="inputTableHeadingBold">
            {translate("deleteInput")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <Typography sx={inputHeading} variant="inputTableCellName">
            {translate("areYouSure")} <b>{inputDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography sx={inputHeading} variant="inputHeadingBoldButton">
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteInput()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : dialogDeleteButton
            }
          >
            <Typography sx={inputHeading} variant="inputHeadingBoldButton">
              {translate("deleteInput")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteInputDialog;
