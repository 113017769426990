import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

const PageContext = createContext();

const PageProvider = ({ children }) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [prevRoute, setPrevRoute] = useState(location.pathname);

  const resetPage = () => setPage(0);

  const updateActiveRoute = (route) => {
    if (prevRoute !== route) {
      resetPage();
    }
    setPrevRoute(route); // Update the previous route
  };

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        resetPage,
        updateActiveRoute,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
