import React, { useContext } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  userHeading,
} from "./muiUserComponentStyles";
import { useDispatch } from "../../redux/store";
import { deleteUser } from "../../redux/slices/UserSlice";
import { useSelector } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteUserDialog = ({
  userDetail,
  userDialog,
  handleCloseDialog,
  filteredList,
  setFilteredList,
  page,
  setPage,
  rowsPerPage,
}) => {
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);

  const dispatch = useDispatch();
  let { loader } = useSelector((state) => state.userReducer);

  const handleDeleteUser = () => {
    dispatch(deleteUser(userDetail.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    if (data.status) {
      console.log("data", data);
      fireToasterContext.fireToasterHandler(true, data.message);
    }
    const newFilteredList = filteredList.filter(
      (user) => user.id !== userDetail.id
    );
    const totalPages = Math.ceil(newFilteredList.length / rowsPerPage);

    // If there are no more items on the current page and we're not on the first page, go back to the previous page
    if (page >= totalPages && page > 0) {
      setPage(page - 1);
    }

    setFilteredList(newFilteredList);
    handleCloseDialog();
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={userDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={userHeading} variant="userTableHeadingBold">
            {translate("deleteUser")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography sx={userHeading} variant="userTableCellName">
            {translate("areYouSure")} <b>{userDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={{
              borderRadius: "10px",
              background: "black",
              "&:hover": { backgroundColor: "black" },
            }}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteUser()}
            disableRipple
            sx={
              loader
                ? {
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                    "&:disabled": {
                      backgroundColor: "#979797",
                    },
                  }
                : {
                    borderRadius: "10px",
                    backgroundColor: "background.bgLayoutB",
                    "&:hover": { backgroundColor: "background.bgLayoutB" },
                    "&:disabled": {
                      backgroundColor: "background.bgLayoutB",
                    },
                  }
            }
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              {translate("deleteUser")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteUserDialog;
