import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "axios";

const initialState = {
  loggedInUsersPermission: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getLoggedInUsersPermission(state, action) {
      state.loggedInUsersPermission = action.payload.data;
    },
  },
});

export default slice.reducer;

export function loginUser(data, handleLoginFormData) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/auth/login`,
        data
      );

      // const permissionsResponse = await axios.get(
      //   `${process.env.REACT_APP_HOST_API_KEY}permissions/permissions-for-loggedin-user`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${response?.data?.data?.token}`,
      //     },
      //   }
      // );

      // localStorage.setItem("permissions", permissionsResponse.data.data);

      if (handleLoginFormData) {
        handleLoginFormData(response?.data);
      }
    } catch (error) {
      if (handleLoginFormData) {
        handleLoginFormData(error?.response?.data);
      }
    }
  };
}

export function verifyOTP(data, handleLoginOTPFormData) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/auth/verify/${data.id}`,
        // { otp: data.otp }
        data
      );

      const permissionsResponse = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/permissions/permissions-for-loggedin-user`,
        {
          headers: {
            Authorization: `Bearer ${response?.data?.data?.token}`,
            timezoneutcoffset: new Date().getTimezoneOffset(),
          },
        }
      );
      console.log("permission", permissionsResponse);
      localStorage.setItem("permissions", permissionsResponse.data.data);

      if (permissionsResponse) {
        handleLoginOTPFormData(response?.data, permissionsResponse.data);
      }
    } catch (error) {
      console.log(error);
      if (handleLoginOTPFormData) {
        handleLoginOTPFormData(error?.response?.data?.message);
      }
    }
  };
}
