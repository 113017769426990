import React, { useContext, useEffect, useRef, useState } from "react";
import {
  cancelModalButtonsProfile,
  deleteIconButtonsProfile,
  editIconButtonsProfile,
  profileCreateButton,
  profileNameAvatar,
  profileSearchField,
  profileSearchFieldIcon,
  profileTableCell,
  profileTableContainer,
  profileTableHead,
  profileTableHeadCell,
} from "./ProfileStyle";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import {
  getProfilesList,
  deleteProfile,
  getUsersProfileList,
} from "../../redux/slices/profileSlice";
import { getRoomsList } from "../../redux/slices/RoomSlice";
import CustomPagination from "../../hooks/useTablePagination";
import Loader from "../Loader/Loader";
import toasterContext from "../../utils/context/toasterContext";
import useLocales from "../../hooks/useLocales";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContext } from "../../utils/context/PageContext";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const { translate } = useLocales();
  const { page, setPage, updateActiveRoute } = useContext(PageContext);
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderDelete, setLoaderDelete] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState();
  const fireToasterContext = useContext(toasterContext);

  useEffect(() => {
    dispatch(getUsersProfileList());
    dispatch(getProfilesList(handleProfileResponse));
    dispatch(getRoomsList());
    updateActiveRoute(location.pathname);
  }, []);

  const handleProfileResponse = () => {
    setLoader(false);
  };

  const { profilesList } = useSelector((state) => state.profileReducer);
  const { profileUsers } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(profilesList);
    }
  }, [profilesList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    profilesList.map((item) => {
      const name = item.name.toString().toLowerCase();
      const secondryEscalationProfileName =
        getProfileName(item.secondryEscalationProfileId) || "";
      const tertiaryEscalationProfileName =
        getProfileName(item.tertiaryEscalationProfileId) || "";
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (secondryEscalationProfileName?.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (tertiaryEscalationProfileName?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };

  const handleEditProfile = (data) => {
    navigate("/locations/update-location", {
      state: { selectedProfile: data },
    });
  };

  const handleOpenConfirmationPopup = (profile) => {
    setProfileDetail(profile);
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleDeleteProfileResponse = (data) => {
    setLoaderDelete(false);
    if (data.status) {
      setOpenDeleteModal(false);
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Location Deleted"
      );

      // Check if we need to go back a page after deletion
      const newFilteredList = filteredList.filter(
        (location) => location.id !== profileDetail.id
      );
      const totalPages = Math.ceil(newFilteredList.length / rowsPerPage);

      // If there are no more items on the current page and we're not on the first page, go back to the previous page
      if (page >= totalPages && page > 0) {
        setPage(page - 1);
      }

      setFilteredList(newFilteredList);
    }
  };

  const getProfileName = (id) => {
    let profileName;
    profileUsers?.map((item) => {
      if (item?.id == id) profileName = item.name;
    });
    return profileName;
  };

  const handleDeleteProfile = () => {
    setLoaderDelete(true);
    dispatch(deleteProfile(profileDetail.id, handleDeleteProfileResponse));
  };

  return (
    <>
      {/* search field grid */}
      <Grid container spacing={2}>
        {localStorage?.getItem("permissions")?.includes("create profile") ? (
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={profileSearchField}
              placeholder={translate("searchProfile")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={profileSearchFieldIcon} />}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={profileSearchField}
              placeholder={translate("searchProfile")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={profileSearchFieldIcon} />}
            />
          </Grid>
        )}
        {localStorage?.getItem("permissions")?.includes("create profile") ? (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button
              type="submit"
              variant="outlined"
              onClick={() => navigate("/locations/add-location")}
              sx={profileCreateButton}
            >
              {translate("createLocation")}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      {/* profile listing table */}
      <TableContainer sx={profileTableContainer}>
        <Table>
          <TableHead sx={profileTableHead}>
            <TableRow>
              <TableCell sx={profileTableHeadCell}>
                {translate("name")}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </TableCell>
              {loader ? null : profilesList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={profileTableHeadCell}>
                    {translate("secondryEscalationProfileId")}
                  </TableCell>
                  <TableCell sx={profileTableHeadCell}>
                    {translate("tertiaryEscalationProfileId")}
                  </TableCell>
                  {localStorage
                    ?.getItem("permissions")
                    ?.includes("update profile" || "delete profile") ? (
                    <TableCell
                      sx={profileTableHeadCell}
                      align="right"
                    ></TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: "background.bgTableRowHover",
              },
            }}
          >
            {loader ? (
              <TableRow
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </TableRow>
            ) : profilesList.length < 1 ? (
              <TableRow>
                <TableRow
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {translate("noProfile")}
                </TableRow>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((profile, index) => (
                  <TableRow
                    sx={{
                      backgroundColor:
                        index % 2
                          ? "background.bgTableEvenRow"
                          : "background.bgTableOddRow",
                    }}
                    key={profile.id}
                  >
                    <TableCell component="th" scope="row" sx={profileTableCell}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>
                          <Avatar sx={profileNameAvatar}>
                            {profile.name.slice(0, 1)}
                          </Avatar>
                        </span>
                        <span>{profile.name}</span>
                      </div>
                    </TableCell>
                    <TableCell sx={profileTableCell}>
                      {getProfileName(profile.secondryEscalationProfileId)}
                    </TableCell>
                    <TableCell sx={profileTableCell}>
                      {getProfileName(profile.tertiaryEscalationProfileId)}
                    </TableCell>
                    {localStorage
                      ?.getItem("permissions")
                      ?.includes("update profile" || "delete profile") ? (
                      <TableCell align="right">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("update profile") ? (
                            <IconButton
                              sx={editIconButtonsProfile}
                              onClick={() => {
                                handleEditProfile(profile);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("delete profile") ? (
                            <IconButton
                              sx={deleteIconButtonsProfile}
                              onClick={() =>
                                handleOpenConfirmationPopup(profile)
                              }
                            >
                              <DeleteOutlineOutlined />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </span>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />

      {openDeleteModal && (
        <Dialog
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          fullWidth
        >
          <DialogTitle
            sx={{
              backgroundColor: "background.bgLayoutA",
              color: "white",
              fontFamily: "Poppins-semibold",
              fontSize: "16px",
            }}
          >
            {translate("deleteLocationOf")} &quot;{profileDetail.name}&quot;
          </DialogTitle>
          <DialogContent dividers>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "14px" }}
            >
              {translate("areYouSure")}
              <b>&quot;{profileDetail.name}&quot;</b> {translate("profile")}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              sx={cancelModalButtonsProfile}
              onClick={() => setOpenDeleteModal(false)}
            >
              {translate("cancel")}
            </Button>
            <Button
              type="submit"
              sx={
                loaderDelete
                  ? {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
              onClick={handleDeleteProfile}
            >
              {translate("deleteLocation")}
              {loaderDelete ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Index;
