import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  groupList: [],
  myGroupList: [],
  roomCreationInputList: [],
};

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    getGroups(state, action) {
      state.groupList = action.payload.data;
    },
    getMyGroups(state, action) {
      state.myGroupList = action.payload.data;
    },
    getRoomCreationInputs(state, action) {
      state.roomCreationInputList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getGroupList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/groups`
        // { headers }
      );
      dispatch(slice.actions.getGroups(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function createGroup(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/groups`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getGroupList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
export function updateGroup(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/groups/${id}`,
        data
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getGroupList());
    } catch (error) {
      handleApiRes(error);
      console.log(error.response.data.message);
    }
  };
}
export function deleteGroup(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/groups/${id}`
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getGroupList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data);
    }
  };
}

export function getMyGroupList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/groups/get-my-groups`
        // { headers }
      );
      dispatch(slice.actions.getMyGroups(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
